import React, { useState } from "react";
import {
  Col,
  Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Card,
  Nav,
} from "reactstrap";
import PersonalDetails from "./PersonalDetails";
import SaathiEducationEdit from "./SaathiEducationEdit";
import SaathiBankDetailsEdit from "./SaathiBankDetailsEdit";
import SaathiCertificationEdit from "./SaathiCertificationEdit";
import SaathiBusinessEdit from "./SaathiBusinessEdit";
import SaathiAgreement from "./SaathiAgreement";
import KycDetails from "./KycDetails";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "app/appSlice";
import { useGetSathiDetailsbyIdQuery } from "../SaathiApiSlice";

const AddNewAgraniSathi = () => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state) => state.app);
  const handleTabs = (value) => dispatch(setActiveTab(value));

  const {
    data: saathiDetails,
    refetch: saathiDetailsRefetch,
  } = useGetSathiDetailsbyIdQuery(localStorage.getItem("applicant_id"));

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 7) {
        dispatch(setActiveTab(tab));
      }
    }
  }

  // console.log("saathiDetails",saathiDetails)

  return (
    <React.Fragment>
      <Container fluid className="margin_top">
        <Col sm="12" className="px-0 ">
          <Card className="mb-0">
            <div className="p-3">
              <Row>
                <ul>
                  <Nav fill pills className="gap-1">
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === 1
                            ? "ColouredClass"
                            : !saathiDetails?.data?.firstName
                            ? "basicNavClass"
                            : "successClass"
                        }
                        onClick={() => {
                          handleTabs(1);
                        }}
                      >
                        <h5>Personal Details</h5>{" "}
                        {!saathiDetails?.data?.firstName ? (
                          ""
                        ) : (
                          <span className="mdi mdi-check-circle-outline "></span>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === 2
                            ? "ColouredClass"
                            : !saathiDetails?.data?.personal_details
                                ?.aadharNumber
                            ? "basicNavClass"
                            : "successClass"
                        }
                        onClick={() => {
                          handleTabs(2);
                        }}
                      >
                        <h5>KYC Details </h5>

                        {!saathiDetails?.data?.personal_details
                          ?.aadharNumber ? (
                          ""
                        ) : (
                          <span className="mdi mdi-check-circle-outline "></span>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === 3
                            ? "ColouredClass"
                            : !saathiDetails?.data?.education_details
                            ? "basicNavClass"
                            : "successClass"
                        }
                        onClick={() => {
                          handleTabs(3);
                        }}
                      >
                        <h5> Education Details </h5>

                        {!saathiDetails?.data?.education_details ? (
                          ""
                        ) : (
                          <span className="mdi mdi-check-circle-outline "></span>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === 4
                            ? "ColouredClass"
                            : !saathiDetails?.data?.bank_details
                            ? "basicNavClass"
                            : "successClass"
                        }
                        onClick={() => {
                          handleTabs(4);
                        }}
                      >
                        <h5> Bank Details </h5>

                        {!saathiDetails?.data?.bank_details ? (
                          ""
                        ) : (
                          <span className="mdi mdi-check-circle-outline "></span>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === 5
                            ? "ColouredClass"
                            : !saathiDetails?.data?.certification_details
                            ? "basicNavClass"
                            : "successClass"
                        }
                        onClick={() => {
                          handleTabs(5);
                        }}
                      >
                        <h5>Certification Details </h5>

                        {!saathiDetails?.data?.certification_details ? (
                          ""
                        ) : (
                          <span className="mdi mdi-check-circle-outline "></span>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === 6
                            ? "ColouredClass"
                            : !saathiDetails?.data?.business_details
                            ? "basicNavClass"
                            : "successClass"
                        }
                        onClick={() => {
                          handleTabs(6);
                        }}
                      >
                        <h5>Business Details </h5>

                        {!saathiDetails?.data?.business_details ? (
                          ""
                        ) : (
                          <span className="mdi mdi-check-circle-outline "></span>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === 7
                            ? "ColouredClass"
                            : saathiDetails?.data?.is_agreed === true
                            ? "successClass"
                            : "basicNavClass"
                        }
                        onClick={() => {
                          handleTabs(7);
                        }}
                      >
                        <h5> Terms & Conditions </h5>

                        {saathiDetails?.data?.is_agreed === true ? (
                          <span className="mdi mdi-check-circle-outline "></span>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </ul>
              </Row>

              <div className="">
                <TabContent
                  activeTab={activeTab}
                  style={{ padding: "0rem", border: "0" }}
                >
                  <TabPane tabId={1}>
                    <PersonalDetails
                      saathiDetails={saathiDetails}
                      saathiDetailsRefetch={saathiDetailsRefetch}
                    />
                  </TabPane>
                  <TabPane tabId={2}>
                    <h2 className="mb-lg-4 mb-md-4 mb-2 mt-3 text-center">
                      Fill the KYC Details in
                      <span className="f_700"> Application</span>
                    </h2>
                    <KycDetails
                      saathiDetails={saathiDetails}
                      saathiDetailsRefetch={saathiDetailsRefetch}
                    />
                  </TabPane>
                  <TabPane tabId={3}>
                    <h2 className="mb-lg-4 mb-md-4 mb-2 mt-3 text-center">
                      Fill the Education Details in
                      <span className="f_700"> Application</span>
                    </h2>
                    <SaathiEducationEdit
                      saathiDetails={saathiDetails}
                      saathiDetailsRefetch={saathiDetailsRefetch}
                    />
                  </TabPane>
                  <TabPane tabId={4}>
                    <h2 className="mb-lg-4 mb-md-4 mb-2 mt-3 text-center">
                      Fill the Banking Details in
                      <span className="f_700"> Application</span>
                    </h2>

                    <SaathiBankDetailsEdit
                      saathiDetails={saathiDetails}
                      saathiDetailsRefetch={saathiDetailsRefetch}
                    />
                  </TabPane>
                  <TabPane tabId={5}>
                    <h2 className="mb-lg-4 mb-md-4 mb-2 mt-3 text-center">
                      Fill the Certification Details in
                      <span className="f_700"> Application</span>
                    </h2>
                    <SaathiCertificationEdit
                      saathiDetails={saathiDetails}
                      saathiDetailsRefetch={saathiDetailsRefetch}
                    />
                  </TabPane>
                  <TabPane tabId={6}>
                    <h2 className="mb-lg-4 mb-md-4 mb-2 mt-3 text-center">
                      Fill the Business Details in
                      <span className="f_700"> Application</span>
                    </h2>
                    <SaathiBusinessEdit
                      saathiDetails={saathiDetails}
                      saathiDetailsRefetch={saathiDetailsRefetch}
                    />
                  </TabPane>
                  <TabPane tabId={7}>
                    <h2 className="mb-lg-4 mb-md-4 mb-2 mt-3 text-center">
                      Accept Agreement From
                      <span className="f_700"> Application</span>
                    </h2>
                    <SaathiAgreement saathiDetails={saathiDetails} />
                  </TabPane>
                </TabContent>
                <div className="actions clearfix mt-0 mb-3 float-end">
                  <ul className="navigaton_btn">
                    <li
                      className={
                        activeTab === 1 || activeTab === 7
                          ? "d-none"
                          : "previous"
                      }
                    >
                      <a
                        to="#"
                        className="btn  btn-secondary"
                        onClick={() => {
                          toggleTab(activeTab - 1);
                        }}
                      >
                        Previous
                      </a>
                    </li>
                    <li className={activeTab === 7 ? "d-none" : "next"}>
                      <a
                        to="#"
                        className="btn btn-secondary"
                        onClick={() => {
                          toggleTab(activeTab + 1);
                        }}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Container>
    </React.Fragment>
  );
};

export default AddNewAgraniSathi;
