import { selectCurrentToken, selectCurrentUser } from "features/auth/authSlice";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Label, Spinner } from "reactstrap";
import { API_URL } from "app/config";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { setActiveTab } from "app/appSlice";

const PersonalDetails = ({ saathiDetails, saathiDetailsRefetch }) => {
  const dispatch = useDispatch();
  const [Verify, setVerify] = useState(false);
  const [verifyedmobile, setverifyedmobile] = useState(false);
  const [AlreadyVerify, setAlreadyVerify] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [noVerified, setnoVerified] = useState(false);
  const [Alternatephone, setAlternatephone] = useState();
  // const [otpModal, setOtpModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const user_token = useSelector(selectCurrentToken);

  const phonehandleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhoneNumber(value);
  };

  const AlternatephonehandleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setAlternatephone(value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [PersonalDetails, setPersonalDetails] = useState({
    LegalEntityType: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const onInputChange = (event) => {
    setPersonalDetails({
      ...PersonalDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitSaathiData = (data) => {
    setLoader(true);
    var jsonData = {
      individuals: data.LegalEntityType,
      firstName: saathiDetails?.data?.firstName || PersonalDetails?.firstName,
      lastName: saathiDetails?.data?.lastName || PersonalDetails?.lastName,
      phoneNumber: phoneNumber || saathiDetails?.data?.phoneNumber,
      alternatePhoneNumber: data.alternatePhoneNumber,
      email: data.email,
      ui_section_id: "1",
      created_by: currentUser.sub,
      created_by_name: currentUser.name,
      empanelment_agent_id: currentUser.sub,
    };

    fetch(`${API_URL}/empanelment/api/personal-details/`, {
      method: "POST",
      body: JSON.stringify(jsonData),
      headers: {
        Authorization: `Bearer ${user_token}`,
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((resp) => {
        const result = resp;
        if (result.status === 200 || result.status === 201) {
          swal("Success", {
            buttons: false,
            icon: "success",
            title: result.message,
            timer: 1500,
          });
          dispatch(setActiveTab(2));
          setLoader(false);
          saathiDetailsRefetch(localStorage.getItem("applicant_id"));
        } else {
          swal("Success", {
            buttons: false,
            icon: "warning",
            title: result.message,
            timer: 1500,
          });
          setLoader(false);
        }
      });
  };

  //*********api for sending otp**********

  async function sendOtp(e) {
    if (
      phoneNumber.length !== 10 &&
      saathiDetails?.data?.phoneNumber.length !== 10
    ) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${user_token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      `${API_URL}/empanelment/api/login/?email_or_phone=${phoneNumber ||
        saathiDetails?.data?.phoneNumber}&created_by=${currentUser.sub}`,
      requestOptions
    )
      .then((r) => r.json())
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.message);
          setAlreadyVerify(false);
          setVerify(true);
        } else {
          toast.error(result.message);
          // setAlreadyVerify(true);
        }
      });
  }

  //*********api for verifing otp**********

  function verifyOtp(e) {
    if (otp.length !== 4) {
      toast.error("Please enter a valid 4-digit otp code.");
      return;
    }

    var formData = new FormData();
    formData.append(
      "email_or_phone",
      phoneNumber || saathiDetails?.data?.phoneNumber
    );
    formData.append("otp_code", otp);
    formData.append("created_by", currentUser.sub);

    var requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${user_token}`,
      },
    };

    fetch(`${API_URL}/empanelment/api/login/`, requestOptions)
      .then((r) => r.json())
      .then((result) => {
        if (result.status === 200) {
          toast.success("Otp Verified");
          setverifyedmobile(true);
          localStorage.setItem("applicant_id", result?.data?.applicant_id);
          saathiDetailsRefetch(localStorage.getItem("applicant_id"));
          setVerify(false);
        } else {
          toast.error(result.message);
        }
        setnoVerified(true);
      });
  }

  return (
    <section className="col-lg-12">
      <div className="px-lg-4 px-md-4 px-0">
        <div className="login_info pl-0">
          <h2 className="mb-lg-5 mb-2 mt-3 text-center ">
            Fill the Personal Details in
            <span className="f_700"> Application</span>
          </h2>
          <div className="formdetails">
            <form onSubmit={handleSubmit(onSubmitSaathiData)}>
              <div className="row">
                <div className="col-lg-12 form-check text_box highlightpart mx-3">
                  <h6 className="">Legal Entity Type</h6>
                  <div className="col-lg-12 p-0 d-flex">
                    <div className="form-check ps-0 form-check-inline">
                      <input
                        name="LegalEntityType"
                        id="Individuals"
                        type="radio"
                        value="individual"
                        defaultChecked
                        readOnly
                        {...register("LegalEntityType")}
                      />
                      <Label htmlFor="Individuals" className="ms-2">
                        Individuals
                      </Label>
                    </div>
                    <div className="form-check  form-check-inline">
                      <input
                        name="LegalEntityType"
                        id="Proprietorship"
                        type="radio"
                        value="sole_proprietorship"
                        readOnly
                        {...register("LegalEntityType")}
                      />
                      <Label htmlFor="Proprietorship" className="ms-2">
                        Proprietorship
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-2  mb-2 text_box">
                  <Label className="f_p text_c f_400">
                    Mobile Number <small className="text-danger">*</small>
                  </Label>
                  <input
                    className="form-control input_text"
                    name="phoneNumber"
                    placeholder="Enter Mobile Number"
                    defaultValue={
                      phoneNumber || saathiDetails?.data?.phoneNumber
                    }
                    maxLength="10"
                    minLength="10"
                    required
                    // disabled={noVerified === true ? true : false}
                    onChange={phonehandleChange}
                  />
                  {Verify == true ? (
                    ""
                  ) : (
                    <small className="mx-1">Please verify Mobile First</small>
                  )}
                </div>
                {Verify === true ? (
                  ""
                ) : (
                  <div className="col-lg-1 btn-bg-gray pt-1 mt-1">
                    <button
                      type="button"
                      className="btn btn-secondary mt-lg-4 py-3 w-100"
                      onClick={(e) => sendOtp()}
                      name="otp_code"
                      disabled={
                        noVerified === true || phoneNumber == undefined
                          ? true
                          : false
                      }
                    >
                      Send Otp
                    </button>
                  </div>
                )}

                {Verify === true || AlreadyVerify !== false ? (
                  <>
                    <div className="col-lg-2  mb-2 text_box">
                      <Label className="f_p text_c f_400 ">Verify OTP</Label>

                      <div className="otp">
                        <input
                          defaultValue=""
                          value={otp}
                          maxLength="4"
                          minLength="4"
                          className="form-control w"
                          placeholder="Enter Otp"
                          name="otp_code"
                          onChange={(e) => {
                            setOtp(e.target.value.replace(/\D/g, ""));
                          }}
                          disabled={verifyedmobile === true ? true : false}
                        />

                        {errors.otp_code && (
                          <small className="m input-error">
                            Please enter valid OTP
                          </small>
                        )}
                      </div>
                      <div className="col-lg-2 btn-bg-gray position-absolute">
                        <button
                          type="button"
                          className={
                            `${
                              verifyedmobile === true
                                ? "btn-success"
                                : "btn-primary"
                            }` + " btn mt-2  py-2 px-3"
                          }
                          onClick={verifyOtp}
                          // disabled={verifyedmobile === true ? true : false}
                        >
                          {verifyedmobile === true ? "Verified" : "Verify"}{" "}
                          <i class="fas fa-award ml-2"></i>
                        </button>
                        <button
                          type="button"
                          className="resend btn btn-secondary mt-2 py-2 px-3 ms-2 "
                          onClick={(e) => sendOtp()}
                        >
                          Resend
                          <i className="ms-2 fa fa-caret-right"></i>
                        </button>

                        <div className="col-lg-2 btn-bg-gray">
                          {verifyOtp === true ? (
                            <button type="button" className="btn_success">
                              Verified
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="col-lg-3  mb-3 text_box mt-lg-0 mt-md-0 mt-3">
                  <Label className="f_p text_c f_400">
                    First Name <small className="text-danger">*</small>
                  </Label>
                  <input
                    name="firstName"
                    type="text"
                    className="form-control input_text"
                    placeholder="Enter First Name"
                    defaultValue={saathiDetails?.data?.firstName}
                    required
                    disabled={AlreadyVerify !== false ? true : false}
                    onChange={onInputChange}
                  />
                </div>

                <div className="col-lg-3  mb-2 text_box">
                  <Label className="f_p text_c f_400">
                    Last Name <small className="text-danger">*</small>
                  </Label>
                  <input
                    className="form-control input_text"
                    name="lastName"
                    type="text"
                    placeholder="Enter Last Name"
                    defaultValue={saathiDetails?.data?.lastName}
                    required
                    onChange={onInputChange}
                    disabled={AlreadyVerify !== false ? true : false}
                  />
                </div>
                <div
                  className={`   mb-2 text_box ${
                    Verify == true ? " col-lg-2" : "col-lg-3"
                  }`}
                >
                  <Label className="f_p text_c f_400">Email ID</Label>
                  <input
                    className="form-control input_text"
                    name="email"
                    type="email"
                    placeholder="Enter Email ID"
                    defaultValue={saathiDetails?.data?.email}
                    pattern="^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$"
                    onChange={onInputChange}
                  />
                </div>
                <div
                  className={` col-lg-3  mb-2 text_box ${
                    Verify == true ? "mt-4" : ""
                  }`}
                >
                  <Label className="f_p text_c f_400">
                    Alternate Phone Number
                  </Label>
                  <input
                    className="form-control input_text"
                    name="alternatePhoneNumber"
                    defaultValue={
                      saathiDetails?.data?.personal_details
                        ?.alternatePhoneNumber
                    }
                    pattern="^[0-9\b]+$"
                    placeholder="Enter Alternate Phone Number"
                    value={Alternatephone}
                    onChange={AlternatephonehandleChange}
                    maxLength="10"
                    minLength="10"
                  />
                </div>
              </div>

              <div className="col-lg-12 text-center m-auto pb-lg-5 mt-lg-5 py-lg-0 py-md-0 py-3 ">
                <button type="submit" className="btn btn-success p-3">
                  <span class="mdi mdi-content-save "></span>
                  {!saathiDetails?.data?.firstName ? " Submit" : " Update"}{" "}
                  Personal Details &nbsp;
                  {loader ? (
                    <Spinner animation="border" size="sm"></Spinner>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PersonalDetails;
