import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectBreadcrumbs } from "app/appSlice";
import { useEffect, useState } from "react";

const CustomBreadCrumbs = ({ breadcrumbs }) => {
  const breadcrumbsFromState = useSelector(selectBreadcrumbs);

  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    if (breadcrumbsFromState) {
      const currentPageName =
        breadcrumbsFromState[breadcrumbsFromState.length - 1].name;
      setCurrentPage(currentPageName);
    }
  }, [breadcrumbsFromState]);

  if (!breadcrumbsFromState) {
    return null;
  }

  return (
    // <Col lg="12" className="" style={{height:"43px"}}>
    <Col lg="12" className="">
      <div
        className="breadcrumb bg-white d-flex justify-content-between"
        style={{ padding: "8px" }}
      >
        <span className="d-lg-block d-none" style={{ fontWeight: "600" }}>
          {currentPage}
        </span>
        <div className="d-flex">
          {breadcrumbsFromState?.map((item, index) => {
            return (
              <li
                className={`breadcrumb-item ${
                  index === breadcrumbsFromState.length - 1 ? "active" : ""
                }`}
                key={index}
              >
                <Link
                  to={item.link}
                  className={`${
                    index === breadcrumbsFromState.length - 1
                      ? "text-primary"
                      : "text-gray"
                  } text-decoration-none`}
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </div>
      </div>
    </Col>
  );
};

export default CustomBreadCrumbs;
