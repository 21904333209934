import { startTransition, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AgraniHeader = () => {
  const [showTopBar, setShowTopBar] = useState(true);

  useEffect(() => {
    let lastScroll = 0;

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;

      if (currentScroll > lastScroll && currentScroll > 50) {
        setShowTopBar(false);
      } else if (currentScroll <= 50) {
        setShowTopBar(true);
      }

      lastScroll = currentScroll;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const handleClick = (event) => {
    startTransition(() => {
      navigate("/dashboard");
    });
  };

  return (
    <div className="container-fluid fixed-top px-0">
      {/* Top Bar */}
      <div className="d-lg-block d-md-block d-none">
        <div className={`top-bar ${showTopBar ? "visible" : "hidden"}`}>
          <div className=" gx-0 align-items-center justify-content-between d-flex py-2">
            <div className="col-lg-6 px-5 text-start">
              <small>
                <i className="fa fa-map-marker-alt me-2 "></i>Sector 16, Noida,
                Uttar Pradesh
              </small>
              <small className="ms-4">
                <i className="fa fa-envelope me-2"></i>info@leadsconnect.in
              </small>
            </div>
            <div className="col-lg-6 px-5 text-end">
              <small>Follow us:</small>
              <a
                className="text-white-50 ms-3"
                href="https://www.facebook.com/LeadsConnectOfficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="text-white-50 ms-3"
                href="https://x.com/leads_connect"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="text-white-50 ms-3"
                href="https://www.linkedin.com/company/leadsconnect/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="text-white-50 ms-3"
                href="https://www.youtube.com/channel/UCn9ThgpymFTgp53qF1IxpoQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <nav
        className={`navbar navbar-expand-lg py-lg-0 px-lg-5 ${
          !showTopBar ? "fixed-top" : "bg-transparent"
        }`}
        style={{ backgroundColor: "#000000" }}
      >
        <a href="/" className="navbar-brand ms-4 ms-lg-0 d-lg-block d-none">
          <img
            className=""
            src="/assets/img/agrani-logo.png"
            alt="Capital for Farmers"
            style={{ height: "40px" }}
          />
        </a>
        {/* Navbar Toggler for Mobile */}
        <div className="d-lg-none d-md-block w-100 px-2 mt-1">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <a href="/" className="navbar-brand ms-3 ms-lg-0">
                <img
                  className=""
                  src="/assets/img/agrani-logo.png"
                  alt="Capital for Farmers"
                  style={{ height: "35px" }}
                />
              </a>
            </div>
            <div className="d-flex  gap-2">
              <div className="">
                <a
                  className="btn btn-outline-primary py-2 px-3 fs-6 text-decoration-none sign-in"
                  onClick={handleClick}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  Sign In
                </a>
              </div>
              <div className="">
                <a
                  className="btn text-white py-2 px-3 fs-6 text-decoration-none bg-orange"
                  href="https://onboarding.agrani.io"
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          type="button"
          className=" me-3 d-lg-none d-md-none"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          style={{
            outline: "none",
            border: "none",
            background: "transparent",
          }}
        >
          <span className="mdi mdi-format-list-bulleted fs-2 text-white"></span>
        </button> */}
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0 fs-6">
            <a
              href="/"
              className="nav-item nav-link active text-decoration-none"
            >
              Home
            </a>
            <a
              href="/contact"
              className="nav-item nav-link text-decoration-none"
            >
              Contact
            </a>
          </div>

          <div className="d-none d-lg-flex ms-2">
            <a
              className="btn btn-outline-primary py-2 px-3 fs-6 text-decoration-none sign-in"
              onClick={handleClick}
              style={{
                borderRadius: "5px",
              }}
            >
              Sign In
            </a>
          </div>
          <div className="d-none d-lg-flex ms-2">
            <a
              className="btn text-white py-2 px-3 fs-6 text-decoration-none bg-orange"
              href="https://onboarding.agrani.io"
            >
              Create Account
            </a>
          </div>
        </div>
      </nav>
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <span className="mdi mdi-arrow-up-thin fs-3"></span>
      </a>
    </div>
  );
};

export default AgraniHeader;
