import React, { useState, useEffect } from "react";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { API_URL } from "app/config";
import { selectCurrentToken, selectCurrentUser } from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAllDropDownDataQuery } from "services/dropdownApiSlice";
import { setActiveTab } from "app/appSlice";
// import { MasterService } from "services/master.service"

const SaathiBusinessEdit = ({ saathiDetails, saathiDetailsRefetch }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  var applicaintId = localStorage.getItem("applicant_id");
  const { handleSubmit, reset } = useForm();
  const user_token = useSelector(selectCurrentToken);
  const currentUser = useSelector(selectCurrentUser);

  const [IncomeSource, setIncomeSource] = useState([]);
  const [selectedincome, setSelectedIncome] = useState(null);

  const [SoldInsBankproduct, setSoldInsBankproduct] = useState([]);
  function handelSoldInsBankproduct(SoldInsBankproduct) {
    setSoldInsBankproduct(SoldInsBankproduct);
  }
  const [professionaltraining, setprofessionaltraining] = useState("");
  function handelprofessionaltraining(professionaltraining) {
    setprofessionaltraining(professionaltraining);
  }
  // ExamStatusData selectbox
  const PlicyData = [
    {
      label: "Status",
      options: [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ],
    },
  ];

  const { data: dropdownitem } = useAllDropDownDataQuery();

  const IncomeOptions = () => {
    return dropdownitem?.data?.results[0]?.income_type?.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const handleIncomeChange = (selectedOption) => {
    setIncomeSource(selectedOption);
    setSelectedIncome(selectedOption.value);
  };

  const [SaathiData, editSaathiData] = useState({
    source_of_income: "",
    income: "",
    experience_in_insurance: false,
    experience_in_banking: false,
    experience_in_agrani_Input: false,
    experience_in_agrani_output: false,
    is_professional_training_insurance_and_bank_product: "",
    interest_in_selling: "",
  });

  const onInputChange = (event) => {
    if (event.target.type === "checkbox") {
      editSaathiData({
        ...SaathiData,
        [event.target.name]: event.target.checked,
      });
    } else {
      editSaathiData({
        ...SaathiData,
        [event.target.name]: event.target.value,
      });
    }
  };
  // console.log(SaathiData)

  //*****************************On submit***********************************//
  const onSubmitSaathiData = (e) => {
    setLoader(true);
    var formData = new FormData();
    formData.append(
      "source_of_income",
      selectedincome || saathiDetails?.data?.business_details?.income_source
    );
    formData.append(
      "income",
      SaathiData.income || saathiDetails?.data?.business_details?.income
    );
    formData.append(
      "experience_in_insurance",
      !SaathiData.experience_in_insurance
        ? false
        : SaathiData.experience_in_insurance || ""
    );
    formData.append(
      "experience_in_agrani_Input",
      !SaathiData.experience_in_agrani_Input
        ? false
        : SaathiData.experience_in_agrani_Input || ""
    );
    formData.append(
      "experience_in_banking",
      !SaathiData.experience_in_banking
        ? false
        : SaathiData.experience_in_banking || ""
    );
    formData.append(
      "experience_in_agrani_output",
      !SaathiData.experience_in_agrani_output
        ? false
        : SaathiData.experience_in_agrani_output || ""
    );
    formData.append(
      "is_professional_training_insurance_and_bank_product",
      professionaltraining ||
        saathiDetails?.data?.business_details
          ?.is_professional_training_insurance_and_bank_product == "Yes"
        ? true
        : false
    );
    formData.append(
      "interest_in_selling",
      SaathiData?.interest_in_selling ||
        saathiDetails?.data?.business_details?.interest_in_selling
    );
    formData.append("ui_section_id", "6");
    formData.append("created_by", currentUser.sub);
    formData.append("created_by_name", currentUser.name);
    formData.append("applicant_id", applicaintId);

    fetch(`${API_URL}/empanelment/api/business-details/`, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Token ${user_token}` },
    }).then((resp) => {
      const result = resp;
      if (result.status === 200 || result.status === 201) {
        resp
          .json()
          .then((response) => {
            swal("Success", {
              buttons: false,
              icon: "success",
              title: response.message,
              timer: 1500,
            });
            setLoader(false);
            dispatch(setActiveTab(7));
            saathiDetailsRefetch(localStorage.getItem("applicant_id"));
          })
          .catch((err) => console.log(err));
      } else {
        result
          .json()
          .then((response) => {
            swal("...Ooops", {
              buttons: false,
              icon: "warning",
              title: response.message,
              timer: 1500,
            });
            setLoader(false);
          })
          .catch((err) => console.log(err));
      }
    });
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 48,
    }),
  };
  useEffect(() => {
    if (saathiDetails?.data?.business_details) {
      editSaathiData({ ...saathiDetails?.data?.business_details });
    }
  }, [saathiDetails?.data?.business_details]);

  useEffect(() => {
    if (
      (
        saathiDetails?.data?.business_details?.experiences?.experience_fields ||
        []
      ).length > 0
    ) {
      var experienceIn =
        saathiDetails?.data?.business_details?.experiences?.experience_fields;
      editSaathiData((prev) => {
        return {
          ...prev,
          experience_in_insurance: experienceIn.includes("1"),
          experience_in_banking: experienceIn.includes("2"),
          experience_in_agrani_Input: experienceIn.includes("3"),
          experience_in_agrani_output: experienceIn.includes("4"),
        };
      });
    }
  }, [saathiDetails?.data?.business_details]);

  // console.log(SaathiData , "saathiDetails")

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmitSaathiData)} className="p-2">
        <Col lg="12">
          <Row>
            <Col lg={3} className="mb-3">
              <Label
                className="f_p text_c f_400"
                name="is_insurance_exam_passed"
              >
                Select Primary Source of Income
              </Label>
              <Select
                value={IncomeSource}
                onChange={(e) => handleIncomeChange(e)}
                options={IncomeOptions()}
                styles={customStyles}
                placeholder={
                  saathiDetails?.data?.business_details?.income_source ||
                  "Select Income Source"
                }
              />
            </Col>
            <Col lg={3} className=" text_box ">
              <Label className="f_p text_c f_400">Income</Label>
              <input
                className="form-control"
                name="income"
                type="text"
                placeholder="Enter income"
                required
                onChange={onInputChange}
                defaultValue={saathiDetails?.data?.business_details?.income}
              />
            </Col>
            <Col
              lg={3}
              className="col-lg-4 mb-lg-4 mb-md-3 mx-2 mb-0 mt-lg-0 mt-md-0 mt-3"
            >
              <Label className="f_p text_c f_400 m-0 ">Experience in : </Label>

              <div className="d-flex form-check text_box ps-0 flex-wrap">
                <div className="form-check me-3 ps-0 d-flex align-items-center">
                  <input
                    onChange={onInputChange}
                    className="form-check-Input"
                    type="checkbox"
                    name="experience_in_insurance"
                    id="ExperienceinInsurance"
                    checked={SaathiData?.experience_in_insurance}
                  />
                  <Label className="form-check-Label ms-2 mt-1" htmlFor="Yes">
                    Insurance
                  </Label>
                </div>

                <div className="form-check me-3 d-flex align-items-center">
                  <input
                    onChange={onInputChange}
                    className="form-check-Input"
                    type="checkbox"
                    name="experience_in_banking"
                    checked={SaathiData?.experience_in_banking}
                  />
                  <Label className="form-check-Label  ms-2 mt-1" htmlFor="Yes">
                    Banking
                  </Label>
                </div>

                <div className="form-check me-3 d-flex align-items-center">
                  <input
                    onChange={onInputChange}
                    className="form-check-Input"
                    type="checkbox"
                    name="experience_in_agrani_Input"
                    checked={SaathiData?.experience_in_agrani_Input}
                  />

                  <Label className="form-check-Label  ms-2 mt-1" htmlFor="Yes">
                    Agri Input
                  </Label>
                </div>

                <div className="form-check me-3 d-flex align-items-center">
                  <input
                    onChange={onInputChange}
                    className="form-check-Input"
                    type="checkbox"
                    name="experience_in_agrani_output"
                    checked={SaathiData?.experience_in_agrani_output}
                  />
                  <Label className="form-check-Label  ms-2 mt-1" htmlFor="Yes">
                    Agri output
                  </Label>
                </div>
              </div>
            </Col>
            <Col lg={5} className="mb-3">
              <Label className="f_p text_c f_400">
                Have you taken professional training for insurance or banking
              </Label>
              <Select
                value={
                  professionaltraining === undefined
                    ? professionaltraining
                    : professionaltraining.label
                }
                onChange={(professionaltraining) => {
                  handelprofessionaltraining(professionaltraining.value);
                }}
                options={PlicyData}
                styles={customStyles}
                placeholder={
                  saathiDetails?.data?.business_details
                    ?.is_professional_training_insurance_and_bank_product ===
                  true
                    ? "Yes"
                    : "No" || "Select ..."
                }
              />
            </Col>
            <Col lg={5} className="form-check text_box mx-3">
              <Label className="f_p text_c f_400 m-0">
                You are interested in selling :{" "}
              </Label>
              <div className="col-lg-12 p-0 d-flex align-items-center">
                <div className="form-check form-check-inline ps-0 d-flex align-items-center">
                  <input
                    onChange={onInputChange}
                    name="interest_in_selling"
                    className="mt-1"
                    type="radio"
                    value="InsuranceProducts"
                    readOnly
                    checked={
                      SaathiData.interest_in_selling == "InsuranceProducts"
                    }
                  />
                  <Label htmlFor="InsuranceProducts" className="ms-2 mb-0">
                    Insurance Products
                  </Label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center">
                  <input
                    onChange={onInputChange}
                    name="interest_in_selling"
                    className="mt-1"
                    type="radio"
                    value="BankingProducts"
                    checked={
                      SaathiData.interest_in_selling === "BankingProducts"
                    }
                  />
                  <Label htmlFor="BankingProducts" className="ms-2 mb-0">
                    Banking Products
                  </Label>
                </div>
                <div className="form-check form-check-inline d-flex align-items-center">
                  <input
                    onChange={onInputChange}
                    name="interest_in_selling"
                    className="mt-1"
                    type="radio"
                    value="Both"
                    checked={SaathiData.interest_in_selling === "Both"}
                    readOnly
                  />
                  <Label
                    htmlFor="Both"
                    value="both"
                    className="ms-2 mb-0"
                    onChange={onInputChange}
                  >
                    Both
                  </Label>
                </div>
              </div>
            </Col>
            <Col
              lg={12}
              className="text-center m-auto pb-lg-5  mt-lg-5 py-lg-0 py-3 py-md-3"
            >
              <button
                type="submit"
                className="btn btn-success text-white p-3 ms-3"
              >
                <span class="mdi mdi-content-save "></span>{" "}
                {saathiDetails?.data.business_details === null ||
                saathiDetails?.data.business_details === ""
                  ? " Submit"
                  : " Update"}{" "}
                Business Details &nbsp;
                {loader ? <Spinner animation="border" size="sm"></Spinner> : ""}
              </button>
            </Col>
          </Row>
        </Col>
      </form>
    </React.Fragment>
  );
};

export default SaathiBusinessEdit;
