import React, { useState, useEffect } from "react";
import {
  useGetBuyerNotificationsQuery,
  useMarkNotificationReadMutation,
} from "./ProcurementApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import {
  Typography,
  Checkbox,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
} from "@mui/material";

export const BuyerNotification = () => {
  const currentUser = useSelector(selectCurrentUser);
  const organization_id = currentUser?.organization_id;

  // Fetch notifications
  const {
    data: notifications,
    isLoading,
    refetch,
  } = useGetBuyerNotificationsQuery(organization_id, {
    refetchOnMountOrArgChange: true,
  });

  // Mutation to mark notification as read
  const [markNotificationAsRead] = useMarkNotificationReadMutation();

  // Handle marking notifications as read
  const handleMarkAsRead = async (id) => {
    let payload = {
      is_read: true,
    };
    try {
      await markNotificationAsRead({ id, payload });
      // Optionally refetch notifications after marking as read
      refetch();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Take Action on Notifications
      </Typography>
      <Grid container spacing={2}>
        {notifications?.data?.map((notification) => (
          <Grid item xs={12} key={notification.id}>
            <Card
              variant="outlined"
              style={{ display: "flex", alignItems: "center", padding: "10px" }}
            >
              <CardContent style={{ flex: 1 }}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold", marginBottom: "8px" }}
                >
                  {notification.category}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: "8px" }}>
                  {notification.message}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {new Date(notification.timestamp).toLocaleDateString()} |{" "}
                  {new Date(notification.timestamp).toLocaleTimeString()}
                </Typography>
              </CardContent>
              <div>
                <Checkbox
                  checked={notification.is_read}
                  onChange={() => handleMarkAsRead(notification.id)}
                  color="primary"
                  disabled={notification.is_read}
                />
                <Typography variant="caption">
                  {notification.is_read ? "Read" : "Mark as Read"}
                </Typography>
              </div>
            </Card>
          </Grid>
        ))}
        {!notifications?.data?.length && (
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              No notifications available.
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
