import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Logo from "assets/images/logo.svg";
import LogoMini from "assets/images/logo-mini.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  selectCurrentUser,
  selectCurrentRoles,
  selectLanguage,
  setLanguage,
} from "features/auth/authSlice";
import { toast } from "react-toastify";
import { useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import {
  resetActiveCard,
  resetActiveCardSurvey,
  resetActiveSession,
} from "features/Procurement/common/createCardSlice";
import { BuyerNotification } from "features/Procurement/common/BuyerNotification";
import { useGetBuyerNotificationsQuery } from "features/Procurement/common/ProcurementApiSlice";
const Navbar = () => {
  const { t, i18n } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  let organization_id = currentUser?.organization_id;
  const {
    data: notifications,
    isLoading,
    refetch,
  } = useGetBuyerNotificationsQuery(organization_id, {
    refetchOnMountOrArgChange: true,
  });
  const RoleAcessParams = currentUser?.realm_access?.roles;
  const RolesFromToken = useSelector(selectCurrentRoles);
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();
  console.log("currentUser", currentUser);
  const auth = useAuth();

  const handleLogout = async (e) => {
    e.preventDefault();
    toast.success("Logged Out Successfully");
    dispatch(logOut());
    dispatch(resetActiveCard());
    dispatch(resetActiveCardSurvey());
    dispatch(resetActiveSession());
    auth.removeUser();
    auth.signoutRedirect({ id_token_hint: auth.user.id_token });
    localStorage.removeItem("locationStore");
    localStorage.removeItem("districtName");
    localStorage.removeItem("locationList");
    localStorage.removeItem("locationDropdown");
    localStorage.removeItem("UpDashTab" || t("seasonal_lulc"));
  };

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/dashboard">
          <img src={Logo} alt="logo" />
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/dashboard">
          <img src={LogoMini} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-space-between">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <span className="mdi mdi-menu"></span>
        </button>

        <ul className="navbar-nav navbar-nav-right px-4">
          {/* {RolesFromToken?.groups?.includes("Checker") ||
          RolesFromToken?.groups?.includes("Maker") || RolesFromToken?.groups?.includes("Banker") ||  RolesFromToken?.groups?.includes("Bank_Of_Baroda_Banker") ||RoleAcessParams.includes("CBBO") ||RoleAcessParams.includes("FPO") ? (
            ""
          ) : (
            <div className="col-lg-3 col-8">
              <Input
                type="select"
                value={language}
                className=" fs-6 border-0 "
                style={{
                  padding: "10px",
                  borderRadius: "0rem",
                  border: "1px solid rgba(151, 151, 151, 0.3)",
                }}
                required
                onChange={(e) => {
                  dispatch(setLanguage(e.target.value));
                }}
              >
                <option value="en">{t("English")}</option>
                <option value="hi">{t("Hindi")}</option>
              </Input>
            </div>
          )} */}
          <li className="nav-item nav-profile nav-language">
            <Dropdown alignright="true">
              <Dropdown.Toggle className="nav-link count-indicator">
                <div className="nav-profile-img">
                  <img src={currentUser?.profile} alt="profile" />
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">
                    <Trans>{currentUser?.name}</Trans>
                  </p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown col-lg-12 col-3">
                <div className="p-3 text-center bg-primary">
                  <img
                    className="img-avatar img-avatar48 img-avatar-thumb"
                    src={currentUser?.profile}
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <h5 className="dropdown-header text-uppercase pl-2 text-dark">
                    <Trans>User Options</Trans>
                  </h5>
                  {RoleAcessParams?.includes("admin") ? (
                    <Link
                      to="/feedback"
                      className="text-decoration-none text-black"
                    >
                      <Dropdown.Item
                        className="dropdown-item d-flex align-items-center justify-content-between"
                        href="!#"
                      >
                        <span>
                          <Trans>Feedback</Trans>
                        </span>
                        <span className="p-0">
                          <span className="badge badge-primary">3</span>
                          <i className="mdi mdi-email-open-outline ml-1"></i>
                        </span>
                      </Dropdown.Item>
                    </Link>
                  ) : (
                    ""
                  )}

                  {RoleAcessParams?.includes("admin") ||
                  RolesFromToken?.groups?.includes("Banker") ||
                  RolesFromToken?.groups?.includes("Bank_Of_Baroda_Banker") ? (
                    <Dropdown.Item className="dropdown-item d-flex align-items-center justify-content-between">
                      <span>
                        <Trans>Profile</Trans>
                      </span>
                      <span className="p-0">
                        <span className="badge badge-success">1</span>
                        <i className="mdi mdi-account-outline ml-1"></i>
                      </span>
                    </Dropdown.Item>
                  ) : (
                    <Link
                      to={
                        RoleAcessParams.includes("TRADER") ||
                        RoleAcessParams.includes("MANUFACTURER")
                          ? `/completeProfile/${currentUser.organization_id}`
                          : "/user_profile"
                      }
                      className="text-decoration-none text-black"
                    >
                      <Dropdown.Item
                        className="dropdown-item d-flex align-items-center justify-content-between"
                        href="!#"
                      >
                        <span>
                          <Trans>Profile</Trans>
                        </span>
                        <span className="p-0">
                          <span className="badge badge-success">1</span>
                          <i className="mdi mdi-account-outline ml-1"></i>
                        </span>
                      </Dropdown.Item>
                    </Link>
                  )}

                  <div role="separator" className="dropdown-divider"></div>

                  <Dropdown.Item
                    className="dropdown-item d-flex align-items-center justify-content-between"
                    href="!#"
                    onClick={(event) => handleLogout(event)}
                  >
                    <span>
                      <Trans>Log Out</Trans>
                    </span>
                    <i className="mdi mdi-logout ml-1"></i>
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item d-lg-block d-md-block d-none">
            <Dropdown alignright="true">
              <Dropdown.Toggle
                className="nav-link count-indicator hide-carret"
                onClick={(evt) => evt.preventDefault()}
              >
                <i className="mdi mdi-email-outline"></i>
                <span className="count-symbol bg-warning"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <h6 className="p-3 bg-primary text-white py-4 mb-0">
                  Messages
                </h6>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <img src="" alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                      <Trans>Mark send you a message</Trans>
                    </h6>
                    <p className="text-gray mb-0">
                      1 <Trans>Minutes ago</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <img src="" alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                      <Trans>Cregh send you a message</Trans>
                    </h6>
                    <p className="text-gray mb-0">
                      15 <Trans>Minutes ago</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <img src="" alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                      <Trans>Profile picture updated</Trans>
                    </h6>
                    <p className="text-gray mb-0">
                      18 <Trans>Minutes ago</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center cursor-pointer">
                  4<Trans> new messages</Trans>
                </h6>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item d-lg-block d-md-block d-none">
            <Dropdown alignright="true">
              <Dropdown.Toggle
                className="nav-link count-indicator hide-carret"
                // onClick={(evt) => {
                //   if (currentUser?.organization_type !== "Manufacturers") {
                //     evt.preventDefault();
                //   }
                // }}
              >
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </Dropdown.Toggle>
              {currentUser?.organization_type !== "Manufacturers" ? (
                ""
              ) : (
                <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                  <h6 className="p-3 mb-0 bg-primary text-white py-4">
                    <Trans>Notifications</Trans>
                  </h6>
                  <div className="dropdown-divider"></div>
                  {notifications?.data.slice(0, 1).map((item) => (
                    <>
                      <Dropdown.Item
                        className="dropdown-item preview-item"
                        onClick={(evt) => evt.preventDefault()}
                      >
                        <div className="preview-thumbnail">
                          <div className="preview-icon bg-success">
                            <i className="mdi mdi-calendar"></i>
                          </div>
                        </div>
                        <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                          <h6 className="preview-subject font-weight-normal mb-1">
                            <Trans>Pre-Arrival</Trans>
                          </h6>
                          <p className="text-gray ellipsis mb-0">
                            {" "}
                            <Trans>{item.message}</Trans>{" "}
                          </p>
                        </div>
                      </Dropdown.Item>

                      <div className="dropdown-divider"></div>
                      <h6 className="p-3 mb-0 text-center cursor-pointer">
                        <Trans>
                          {" "}
                          <Link to={"/notifications"}>
                            See all notifications
                          </Link>
                        </Trans>
                      </h6>
                    </>
                  ))}
                  {/* <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <Trans>Event today</Trans>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      {" "}
                      <Trans>
                        Just a reminder that you have an event today
                      </Trans>{" "}
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-settings"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <Trans>Settings</Trans>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      <Trans>Update dashboard</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <Trans>Launch Admin</Trans>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      <Trans>New admin wow!</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center cursor-pointer">
                  <Trans>See all notifications</Trans>
                </h6> */}
                </Dropdown.Menu>
              )}
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
