import React, { useState } from "react";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { selectCurrentToken, selectCurrentUser } from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "app/config";
import { useAllDropDownDataQuery } from "services/dropdownApiSlice";
import { setActiveTab } from "app/appSlice";

const SaathiEducationEdit = ({ saathiDetails, saathiDetailsRefetch }) => {
  const user_token = useSelector(selectCurrentToken);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  var applicaintId = localStorage.getItem("applicant_id");
  const { handleSubmit, reset } = useForm();
  const { data: dropdownitem } = useAllDropDownDataQuery();

  const [Qualificationlist, setQualificationlist] = useState("");
  const [selectedQualification, setSelectedQualification] = useState("");

  function handleQualification(Qualification) {
    setQualificationlist(Qualification);
    setSelectedQualification(Qualification.value);
  }

  const QualificationOptions = () => {
    return dropdownitem?.data?.results[0]?.education?.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const [selectedFile, setSelectedFile] = useState();
  const [IsSelected, setIsSelected] = useState(false);
  const [loader, setLoader] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const [EducationDetails, editEducationDetails] = useState({
    highest_qualification: selectedQualification,
    year_of_passing: "",
    degree_certificate: "",
  });

  const onInputChange = (event) => {
    editEducationDetails({
      ...EducationDetails,
      [event.target.name]: event.target.value,
    });
  };

  //**********On submit**************//

  const onSubmitSaathiData = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("degree_certificate", selectedFile);
    formData.append(
      "highest_qualification",
      selectedQualification ||
        saathiDetails?.data?.education_details?.highest_qualification
    );
    formData.append(
      "year_of_passing",
      EducationDetails.year_of_passing ||
        saathiDetails?.data?.education_details?.year_of_passing
    );
    formData.append("applicant_id", applicaintId);
    formData.append("created_by", currentUser.sub);
    formData.append("created_by_name", currentUser.name);

    await fetch(`${API_URL}/empanelment/api/education-details/`, {
      method: "POST",
      body: formData,
      redirect: "follow",
      headers: { Authorization: `Bearer ${user_token}` },
    }).then((resp) => {
      const result = resp;
      if (result.status === 200 || result.status === 201) {
        resp
          .json()
          .then((response) => {
            swal("Success", {
              buttons: false,
              icon: "success",
              title: response.message,
              timer: 1500,
            });
            dispatch(setActiveTab(4));
            setLoader(false);
            saathiDetailsRefetch(localStorage.getItem("applicant_id"));
          })
          .catch((err) => console.log(err));
      } else {
        result
          .json()
          .then((response) => {
            swal("...Ooops", {
              buttons: false,
              icon: "warning",
              title: response.message,
              timer: 1500,
            });
            setLoader(false);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 48,
    }),
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const isDegreeCertificateAvailable = saathiDetails?.data?.all_media?.find(
    (item) => item.document_type == "Degree"
  );

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmitSaathiData)} className="p-2">
        <Col lg="12">
          <Row>
            <>
              <Col lg="4" className="mb-3">
                <Label className="f_p text_c f_400">
                  Select Highest Qualification{" "}
                  <small className="text-danger">*</small>
                </Label>
                <Select
                  value={Qualificationlist}
                  onChange={(e) => handleQualification(e)}
                  options={QualificationOptions()}
                  styles={customStyles}
                  placeholder={
                    saathiDetails?.data?.education_details
                      ?.highest_qualification || "Select Highest Qualification"
                  }
                  required={
                    saathiDetails?.data?.education_details
                      ?.highest_qualification == "" ||
                    saathiDetails?.data?.education_details
                      ?.highest_qualification == undefined
                      ? true
                      : false
                  }
                />
              </Col>

              <Col lg="2" className="mb-2">
                <Label>Year of Passing</Label>
                <Input
                  type="text"
                  name="year_of_passing"
                  onChange={onInputChange}
                  placeholder="Enter Year"
                  defaultValue={
                    saathiDetails?.data?.education_details?.year_of_passing
                  }
                  required
                  style={customStyles}
                  className="input_text"
                />
              </Col>
              <Col lg={4}>
                <Label className="f_p text_c f_400">
                  Degree Certificate <small>(Image Or Pdf Format Only*)</small>
                </Label>
                <input
                  className="form-control"
                  type="file"
                  name="file"
                  accept="image/*,.pdf"
                  filename="selectedFile"
                  onChange={changeHandler}
                  required={
                    isDegreeCertificateAvailable?.filename !== "" ? false : true
                  }
                />
              </Col>
            </>
          </Row>
        </Col>
        <Col
          lg={12}
          className="text-center m-auto pb-lg-5  mt-lg-5 py-lg-0 py-3 py-md-3"
        >
          <button type="submit" className="btn btn-success text-white p-3 ms-3">
            <span class="mdi mdi-content-save "></span>{" "}
            {saathiDetails?.data.education_details === null ||
            saathiDetails?.data.education_details === ""
              ? " Submit"
              : " Update"}{" "}
            Education Details &nbsp;
            {loader ? <Spinner animation="border" size="sm"></Spinner> : ""}
          </button>
        </Col>
      </form>
    </React.Fragment>
  );
};

export default SaathiEducationEdit;
