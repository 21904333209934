import { apiSlice } from "app/api/apiSlice";
export const ProcurementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStocksData: builder.query({
      query: ({ user_id }) => `procurement/api/stock?user__user_id=${user_id}`,
    }),
    getCommoditiesData: builder.query({
      query: ({ user_id }) =>
        `procurement/api/commodities/available/?user_id=${user_id}`,
    }),
    addStockData: builder.mutation({
      query: (payload) => ({
        url: "procurement/api/stock",
        method: "POST",
        body: payload,
      }),
    }),
    uploadProcMedia: builder.mutation({
      query: (payload) => ({
        url: "procurement/api/stock-location/media-storage",
        method: "POST",
        body: payload,
      }),
    }),
    getUserBasicDetails: builder.query({
      query: ({ user_id }) => `procurement/api/user-details/${user_id}`,
    }),
    createAggregationBySeller: builder.mutation({
      query: (payload) => ({
        url: "procurement/api/procurement-by-seller",
        method: "POST",
        body: payload,
      }),
    }),

    getSampleDataDashboard: builder.query({
      query: (locationName) =>
        `procurement/api/procurement/analytics/?state=${locationName}`,
    }),
    getAdminListFalse: builder.query({
      query: () => `procurement/api/procurement-by-seller?is_approved=false`,
    }),
    uploadInitialUserDetails: builder.mutation({
      query: (payload) => ({
        url: "procurement/api/user-details",
        method: "POST",
        body: payload,
      }),
    }),
    getCommoditiesStockWise: builder.query({
      query: (user_id) => `procurement/api/stock/?user__user_id=${user_id}`,
    }),
    getBuyersList: builder.query({
      query: (entity_type) =>
        `procurement/api/user-details/?entity_type=${entity_type}`,
    }),
    assignByAdmin: builder.mutation({
      query: ({ approvalId, payload }) => ({
        url: `procurement/api/procurement-by-seller/${approvalId}`,
        method: "PATCH",
        body: payload,
      }),
    }),
    getSellerListById: builder.query({
      query: (id) =>
        `procurement/api/procurement-by-seller/?created_by__user_id=${id}`,
    }),
    getAggregationByBuyer: builder.query({
      query: (created_by) =>
        `procurement/api/procurement-by-seller/market-place/?created_by=${created_by}`,
    }),
    getAggregationByBuyerOfSeller: builder.query({
      query: ({ cropId, created_by, cropUnit }) =>
        `procurement/api/procurement-by-seller/sellers/${cropId}/?created_by=${created_by}&unit=${cropUnit}`,
    }),
    uploadBuyerDetails: builder.mutation({
      query: (payload) => ({
        url: "procurement/api/procurement-by-buyer",
        method: "POST",
        body: payload,
      }),
    }),
    getAdminListBuyerFalse: builder.query({
      query: () =>
        `procurement/api/procurement-by-buyer?is_initial_relationship=false&is_approved=false`,
    }),
    assignByAdminBuyer: builder.mutation({
      query: ({ approvalId, payload }) => ({
        url: `procurement/api/procurement-by-buyer/${approvalId}`,
        method: "PATCH",
        body: payload,
      }),
    }),
    getSellerCartData: builder.query({
      query: (user_id) =>
        `procurement/api/self-seller-cart/?created_by=${user_id}`,
    }),
    getInterestedBuyersList: builder.query({
      query: (BuyersActiveId) =>
        `procurement/api/procurement-by-seller/buyers/${BuyersActiveId}`,
    }),
    getSellerCartDataDependent: builder.query({
      query: (user_id) =>
        `procurement/api/association-seller-cart/?is_approved=true&created_by=${user_id}`,
    }),
    getBuyerCartData: builder.query({
      query: (user_id) => `procurement/api/buyer-cart/?created_by=${user_id}`,
    }),
    negotiationAccRej: builder.mutation({
      query: ({ id, payload }) => ({
        url: `procurement/api/procurement/action/${id}`,
        method: "PATCH",
        body: payload,
      }),
    }),
    negotiationCreated: builder.mutation({
      query: (payload) => ({
        url: `procurement/api/negotiation`,
        method: "POST",
        body: payload,
      }),
    }),
    negotiationChatApi: builder.query({
      query: (id) => `procurement/api/negotiation/view-procurement/${id}`,
    }),
    buySellAcceptedBoth: builder.query({
      query: (id) =>
        `procurement/api/procurement-by-buyer/?is_seller_agreed=true&is_buyer_agreed=true`,
    }),
    getBuyerListById: builder.query({
      query: (id) => `procurement/api/procurement-by-buyer/?created_by=${id}`,
    }),
    getShipmentDetails: builder.query({
      query: (procId) => `procurement/api/procurement/invoice-data/${procId}`,
    }),
    postInvoiceData: builder.mutation({
      query: ({ payload, shipmentValue }) => ({
        url: `erp/api/erp/procurement/purchase-invoice/?invoice_type=${shipmentValue}`,
        // url: `erp-gateway/api/procurement/purchase-invoice/?invoice_type=${shipmentValue}`,

        method: "POST",
        body: payload,
      }),
    }),
    // postAndGetInvoiceMedia: builder.query({
    //     query: ({erpName,shipmentValue}) => ({ url: `erp/api/procurement-invoice/api/v1/purchase-invoice-pdf/${erpName}?invoice_type=${shipmentValue}` })
    // }),

    postFileMedia: builder.mutation({
      query: (payload) => ({
        url: "procurement/api/sales-invoice-storage",
        method: "POST",
        body: payload,
      }),
    }),
    getCommoditiesAllData: builder.query({
      query: () => `procurement/api/commodities`,
    }),
    adminCountList: builder.query({
      query: () => `procurement/api/procurement/leads`,
    }),

    onboardUsers: builder.mutation({
      query: (payload) => ({
        url: "erp/api/erp/procurement/suppliers",
        method: "POST",
        body: payload,
      }),
    }),

    getTradeInfo: builder.query({
      query: (supplierName) => ({ url: `erp/api/erp/procurement/trade-info?query=${supplierName}` }),
      // query: (supplierName) => ({ url: `erp-gateway/api/procurement/trade-info?query=${supplierName}` }),

    }),
    getSuppliersInfo: builder.query({
      query: (supplierName) => ({ url: `erp/api/erp/procurement/suppliers?query=${supplierName}` }),
      // query: (supplierName) => ({ url: `erp-gateway/api/procurement/suppliers?query=${supplierName}` }),

    }),
    getSuppliersIdInfo: builder.query({
      query: ({supplier_id,searchKey}) => ({
        url: `erp/api/erp/procurement/suppliers/${supplier_id}?query=${searchKey}`,
        // url: `erp-gateway/api/procurement/suppliers/${supplier_id}?query=${searchKey}`,
      }),
    }),
    sendcommodityData: builder.mutation({
      query: (payload) => ({
        url: "procurement/api/user-commodities/",
        method: "POST",
        body: payload,
      }),
    }),
    getCommoditiesCategories: builder.query({
      query: () => `procurement/api/commodity/categories/`,
    }),
    getIndividualUserCommodity: builder.query({
      query: (user_id) => ({
        url: `procurement/api/user-commodities/?user__user_id=${user_id}`,
      }),
    }),
    getQualityParameter :builder.query({
        query: (code) => ({
          url: `procurement/api/quality-parameter-master/?commodity_id=${code}`,
        }),
      }),
      sendDispatchDoc: builder.mutation({
        query: (formData) => ({
          url: "procurement/api/procurement/shipment/dispatch-record/",
          method: "POST",
          body: formData,
        }),
      }),
      getDispatchAllDocuments :builder.query({
        query: (procCode) => ({
          url: `procurement/api/procurement/shipment/dispatch/?procurement__ProcCode=${procCode}`,
        }),
      }),
      patchDispatchDoc: builder.mutation({
        query: ({id,formPayload}) => ({
          url: `procurement/api/procurement/shipment/dispatch-record/${id}`,
          method: "PATCH",
          body: formPayload,
        }),
      }),
      dispatchProof: builder.mutation({
        query: (formPayload) => ({
          url: `procurement/api/procurement/shipment/dispatch-proof/`,
          method: "POST",
          body: formPayload,
        }),
      }),
      getDeliveryAllDocuments :builder.query({
        query: (procCode) => ({
          url: `procurement/api/procurement/shipment/delivery/?procurement__ProcCode=${procCode}`,
        }),
      }),
      getDeliveryCheck :builder.query({
        query: (ProcCode) => ({
          url: `procurement/api/procurement/shipment/dispatch-record/?dispatch__procurement__ProcCode=${ProcCode}`,
        }),
      }),
      sendDeliveredDoc: builder.mutation({
        query: (formData) => ({
          url: "procurement/api/procurement/shipment/delivery-record/",
          method: "POST",
          body: formData,
        }),
      }),
      patchDeliveryDoc: builder.mutation({
        query: ({id,formPayload}) => ({
          url: `procurement/api/procurement/shipment/delivery-record/${id}`,
          method: "PATCH",
          body: formPayload,
        }),
      }),
      sendPaymentDoc: builder.mutation({
        query: (formData) => ({
          url: "procurement/api/payment/",
          method: "POST",
          body: formData,
        }),
      }),
      uploadPaymentProof: builder.mutation({
        query: (formData) => ({
          url: "procurement/api/payments/proof-upload/",
          method: "POST",
          body: formData,
        }),
      }),
      acceptPaymentBySeller: builder.mutation({
        query: ({ id, payload }) => ({
          url: `procurement/api/payment/action/${id}`,
          method: "PATCH",
          body: payload,
        }),
      }),
      generateInvoiceBackend: builder.mutation({
        query: (payload) => ({
          url: `procurement/api/procurement/invoice`,
          method: "POST",
          body: payload,
        }),
      }),
      getSellerInterestedCommodity :builder.query({
        query: (CommodityName) => ({
          url: `procurement/api/user-details/commodity-users/?commodity=${CommodityName}&entity_type=Seller`,
        }),
      }),
      getSellerAddressInfo: builder.query({
        // query: ({type,UserData}) => ({ url: `erp/api/erp/procurement/address/${type}/${UserData}` }),
        query: (stockId) => ({ url: `procurement/api/stock-location/?stock__id=${stockId}` }),

      }),
     
      postAddressSellandBuy: builder.mutation({
        query: (data ) => ({
          url: `erp/api/erp/procurement/address`,
          //  url: `erp-gateway/api/procurement/address`,

          method: "POST",
          body: data,
        }),
      }),
      disableCommodity: builder.mutation({
        query: ({ id }) => ({
          url: `procurement/api/user-commodities/status/${id}`,
          method: "PATCH",
        }),
      }),
      postAddress: builder.mutation({
        query: (payload ) => ({
          url: `procurement/api/stock-location`,
          method: "POST",
          body: payload,
        }),
      }),
      patchAddress: builder.mutation({
        query: ({payload,addressId} ) => ({
          url: `procurement/api/stock-location/${addressId}`,
          method: "PATCH",
          body: payload,
        }),
      }),
      getAddressInfoList: builder.query({
        query: ({type,UserData}) => ({ url: `erp/api/erp/procurement/address/${type}/${UserData}` }),
        // query: ({type,UserData}) => ({ url: `erp-gateway/api/procurement/address/${type}/${UserData}` }),


      }),
      getInvoiceAddressInfo: builder.query({
        query: ({stockUserId,stockCropId}) => ({ url: `procurement/api/stock-location/?stock__user__user_id=${stockUserId}&stock__crop_id=${stockCropId}` }),

      }),
      getAllInvoicesById: builder.query({
        query: (procId) => ({ url: `procurement/api/procurement/invoices/${procId}/` }),

      }),
      negotiationTimeline: builder.mutation({
        query: ({id,payload} ) => ({
          url: `procurement/api/procurement/negotiations/validity/${id}`,
          method: "PATCH",
          body: payload,
        }),
      }),
      getBuyersAssignFiltersData: builder.query({
        query: ({stateName,districtName,cropName,seasonName,yearName}) => ({ url: `procurement/api/stock/?state_name=${stateName}&district_name=${districtName}&crop_name=${cropName}&season=${seasonName}&year=${yearName}&is_distinct=yes` }),
      }),
      postPurchaseOrder: builder.mutation({
        query: (payload ) => ({
          url: `procurement/api/upload/purchase-order/`,
          method: "POST",
          body: payload,
        }),
      }),
      getDispatchStockAddresses: builder.query({
        query: (procId) => ({ url: `procurement/api/procurement/stock-locations/${procId}` }),
      }),
      getCommoditySpecificSellerList: builder.query({
        query: (CommodityName) => ({ url: `procurement/api/commodity/sellers/${CommodityName}` }),
      }),
      postCheckAvailability: builder.mutation({
        query: (formData) => ({
          url: "procurement/api/stock/check-availability",
          method: "POST",
          body: formData,
        }),
      }),
      getBuyerNotifications: builder.query({
        query: (user_id) => ({
          url: `procurement/api/procurement-notifications/?receivers__user_id=${user_id}&category=Dispatch&is_read=false`,
        }),
      }),
      markNotificationRead: builder.mutation({
        query: ({id,payload} ) => ({
          url: `procurement/api/procurement-notifications/${id}`,
          method: "PATCH",
          body: payload,
        }),
      }),
      postSummaryDahboard: builder.mutation({
        query: (formData) => ({
          url: "procurement/api/procurement/analytic-report",
          method: "POST",
          body: formData,
        }),
      }),
      postReport: builder.mutation({
        query: (formData) => ({
          url: "procurement/api/procurement/summary-report",
          method: "POST",
          body: formData,
        }),
      }),

  }),
  
});

export const {
  useGetStocksDataQuery,
  useGetCommoditiesDataQuery,
  useAddStockDataMutation,
  useUploadProcMediaMutation,
  useGetUserBasicDetailsQuery,
  useCreateAggregationBySellerMutation,
  useGetSampleDataDashboardQuery,
  useGetAdminListFalseQuery,
  useUploadInitialUserDetailsMutation,
  useGetCommoditiesStockWiseQuery,
  useGetBuyersListQuery,
  useAssignByAdminMutation,
  useGetSellerListByIdQuery,
  useGetAggregationByBuyerQuery,
  useGetAggregationByBuyerOfSellerQuery,
  useUploadBuyerDetailsMutation,
  useGetAdminListBuyerFalseQuery,
  useAssignByAdminBuyerMutation,
  useGetSellerCartDataQuery,
  useGetInterestedBuyersListQuery,
  useGetSellerCartDataDependentQuery,
  useGetBuyerCartDataQuery,
  useNegotiationAccRejMutation,
  useNegotiationCreatedMutation,
  useNegotiationChatApiQuery,
  useBuySellAcceptedBothQuery,
  useGetBuyerListByIdQuery,
  useGetShipmentDetailsQuery,
  usePostInvoiceDataMutation,
  usePostFileMediaMutation,
  useGetCommoditiesAllDataQuery,
  useAdminCountListQuery,
  useOnboardUsersMutation,
  useGetTradeInfoQuery,
  useGetSuppliersInfoQuery,
  useGetSuppliersIdInfoQuery,
  useSendcommodityDataMutation,
  useGetIndividualUserCommodityQuery,
  useGetCommoditiesCategoriesQuery,
  useGetQualityParameterQuery,useSendDispatchDocMutation,useSendDeliveredDocMutation,useSendPaymentDocMutation,
  useUploadPaymentProofMutation,useAcceptPaymentBySellerMutation,useGenerateInvoiceBackendMutation,
  useGetSellerInterestedCommodityQuery,useGetSellerAddressInfoQuery,usePostAddressSellandBuyMutation,useDisableCommodityMutation,usePostAddressMutation
  ,usePatchAddressMutation,useGetAddressInfoListQuery,useGetInvoiceAddressInfoQuery,useGetAllInvoicesByIdQuery,useNegotiationTimelineMutation
  ,useGetDispatchAllDocumentsQuery
  ,usePatchDispatchDocMutation,useGetBuyersAssignFiltersDataQuery,usePostPurchaseOrderMutation,
  useDispatchProofMutation,useGetDeliveryCheckQuery,useGetDeliveryAllDocumentsQuery,usePatchDeliveryDocMutation,useGetDispatchStockAddressesQuery,
  useGetCommoditySpecificSellerListQuery,usePostCheckAvailabilityMutation,useGetBuyerNotificationsQuery,useMarkNotificationReadMutation,usePostSummaryDahboardMutation,
  usePostReportMutation
} = ProcurementApiSlice;
