import React, { useState, useEffect } from "react";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { selectCurrentToken, selectCurrentUser } from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetIfscQuery } from "services/BankBranchesApiSlice";
import { API_URL } from "app/config";
import { setActiveTab } from "app/appSlice";

const SaathiBankDetailsEdit = ({ saathiDetails, saathiDetailsRefetch }) => {
  const dispatch = useDispatch();
  const user_token = useSelector(selectCurrentToken);
  const currentUser = useSelector(selectCurrentUser);
  const applicaintId = localStorage.getItem("applicant_id");
  // console.log(id + "id data ")
  const { handleSubmit, reset } = useForm();

  const [selectedFile, setSelectedFile] = useState();
  const [IsSelected, setIsSelected] = useState(false);
  const [loader, setLoader] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const [AccountType, setAccountType] = useState("");
  const [Ifsccode, setIfsccode] = useState("0");
  const [InputValue, setValue] = useState("");

  function handleAccountType(AccountType) {
    setAccountType(AccountType);
  }

  // handle Input change event
  const handleInputChange = (value) => {
    setValue(value);
  };

  const [BankingDetails, editBankingDetails] = useState({
    account_holder_name: "",
    account_number: "",
  });

  const onInputChange = (event) => {
    editBankingDetails({
      ...BankingDetails,
      [event.target.name]: event.target.value,
    });
  };
  // AccountType
  const AccountTypeListData = [
    {
      label: "Account Type",
      options: [
        { value: "saving", label: "Savings Account " },
        { value: "current", label: "Current Account" },
        { value: "others", label: "Other Account" },
      ],
    },
  ];
  //*****************************On submit***********************************//

  const onSubmitSaathiData = (e) => {
    setLoader(true);
    var formData = new FormData();
    formData.append(
      "account_holder_name",
      BankingDetails.account_holder_name ||
        saathiDetails?.data?.bank_details?.account_holder_name
    );
    formData.append(
      "account_number",
      BankingDetails.account_number ||
        saathiDetails?.data?.bank_details?.account_number
    );
    formData.append(
      "account_type",
      AccountType || saathiDetails?.data?.bank_details?.account_type
    );
    formData.append(
      "ifsc_code",
      bankIfscCode || saathiDetails?.data?.bank_details?.ifsc_code
    );
    formData.append(
      "bank_name",
      bankIfscCodeList?.bankName || saathiDetails?.data?.bank_details?.bank_name
    );
    formData.append(
      "bank_branch",
      bankIfscCodeList?.branchName ||
        saathiDetails?.data?.bank_details?.ifsc_code
    );
    formData.append("bank_document", selectedFile || null);
    formData.append("created_by", currentUser.sub);
    formData.append("created_by_name", currentUser.name);
    formData.append("applicant_id", applicaintId);

    fetch(`${API_URL}/empanelment/api/bank-details/`, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Token ${user_token}` },
    }).then((resp) => {
      const result = resp;
      if (result.status === 200 || result.status === 201) {
        resp
          .json()
          .then((response) => {
            swal("Success", {
              buttons: false,
              icon: "success",
              title: response.message,
              timer: 1500,
            });
            dispatch(setActiveTab(5));
            setLoader(false);
            saathiDetailsRefetch(localStorage.getItem("applicant_id"));
          })
          .catch((err) => console.log(err));
      } else {
        result
          .json()
          .then((response) => {
            swal("...Ooops", {
              buttons: false,
              icon: "warning",
              title: response.message,
              timer: 1500,
            });
            setLoader(false);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 48,
    }),
  };

  const [bankIfscCodeList, setIfscCodeList] = useState({
    branchName: "",
    bankName: "",
  });
  const [BankIfscOptions, setBankIfscOptions] = useState([]);
  const [bankIfscCode, setIfscCode] = useState("");

  const { data: bankIfsc } = useGetIfscQuery(bankIfscCode);

  useEffect(() => {
    if (bankIfsc) {
      let bankList = [];
      bankIfsc.data.results.map((bank) => {
        bankList.push({
          value: bank.branch_name,
          label: bank.IFSC,
          branchName: bank.branch_name,
          bankName: bank.bank_name,
        });
      });
      setBankIfscOptions(bankList);
    }
  }, [bankIfsc]);

  const onIfscChange = (e) => {
    setIfscCode(e.label);
    setIfscCodeList(e);
  };
  const onIfscKeyUp = (e) => {
    setIfscCode(e.target.value);
  };

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  const isBankDocAvailable = saathiDetails?.data?.all_media?.find(
    (item) => item.document_type == "Bank Docs"
  );
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmitSaathiData)} className="p-2">
        <Col lg="12">
          <Row>
            {/* Banking Details */}
            <Col lg="3" className="mb-2">
              <Label>Account Holder Name</Label>
              <Input
                type="text"
                name="account_holder_name"
                onChange={onInputChange}
                defaultValue={
                  saathiDetails?.data?.bank_details?.account_holder_name
                }
                placeholder="Enter Name"
                required
                className="input_text"
              />
            </Col>
            <Col lg="3" className="mb-2">
              <Label>Account Number</Label>
              <Input
                type="text"
                name="account_number"
                onChange={onInputChange}
                placeholder="Enter Account Number"
                defaultValue={saathiDetails?.data?.bank_details?.account_number}
                required
                className="input_text"
              />
            </Col>

            <>
              <Col lg="3">
                <div className="mb-3">
                  <Label className="f_p text_c f_400">
                    Select Account Type<small className="text-danger">*</small>
                  </Label>
                  <Select
                    // value={
                    //   AccountType === undefined
                    //     ? AccountType
                    //     : AccountType.label
                    // }
                    onChange={(AccountType) => {
                      handleAccountType(AccountType.value);
                    }}
                    options={AccountTypeListData}
                    styles={customStyles}
                    required={
                      saathiDetails?.data?.bank_details?.account_type
                        ? false
                        : true
                    }
                    placeholder={
                      capitalizeFirstLetter(
                        saathiDetails?.data?.bank_details?.account_type
                      ) || "Select Account type"
                    }
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label>Select IFSC Code </Label>
                  <Select
                    options={BankIfscOptions}
                    onKeyDown={onIfscKeyUp}
                    onChange={onIfscChange}
                    styles={customStyles}
                    required={
                      saathiDetails?.data?.bank_details?.ifsc_code
                        ? false
                        : true
                    }
                    placeholder={
                      capitalizeFirstLetter(
                        saathiDetails?.data?.bank_details?.ifsc_code
                      ) || "Select IFSC Code"
                    }
                  />
                </div>
              </Col>
              <Col lg="3" className="form-group text_box">
                <Label className="f_p text_c f_400">
                  Bank Name <small className="text-danger">*</small>
                </Label>
                <input
                  type="text"
                  name="bank_name"
                  className="form-control input_text"
                  // value={selectedValue !== null ? selectedValue.bank_name : " "}
                  value={bankIfscCodeList?.bankName}
                  disabled
                  placeholder={saathiDetails?.data?.bank_details?.bank_name}
                />
              </Col>
              <Col lg="3" className=" form-group text_box">
                <Label className="f_p text_c f_400">
                  Branch Name <small className="text-danger">*</small>
                </Label>
                <input
                  type="text"
                  name="branch_name"
                  className="form-control input_text"
                  value={bankIfscCodeList?.branchName}
                  disabled
                  placeholder={saathiDetails?.data?.bank_details?.bank_branch}
                />
              </Col>
              <Col lg="6" className="form-group mb-4">
                <Label className="f_p f_400">
                  Upload cancelled Cheque
                  <small className="text-danger">*</small>
                  <small>(img, Doc Or Pdf Format Only*)</small>
                </Label>
                <input
                  type="file"
                  className="form-control"
                  id="OtherDocImg"
                  name="bank_document"
                  filename="selectedFile"
                  onChange={changeHandler}
                  accept="image/jpeg,image/png,application/pdf"
                  required={isBankDocAvailable?.filename !== "" ? false : true}
                />
              </Col>
              <Col
                lg={12}
                className="text-center m-auto pb-lg-5  mt-lg-5 py-lg-0 py-3 py-md-3"
              >
                <button
                  type="submit"
                  className="btn btn-success text-white p-3 ms-3 "
                >
                  <span class="mdi mdi-content-save "></span>{" "}
                  {saathiDetails?.data.bank_details === null ||
                  saathiDetails?.data.bank_details === ""
                    ? " Submit"
                    : " Update"}{" "}
                  Bank Details &nbsp;
                  {loader ? (
                    <Spinner animation="border" size="sm"></Spinner>
                  ) : (
                    ""
                  )}
                </button>
              </Col>
            </>
          </Row>
        </Col>
      </form>
    </React.Fragment>
  );
};

export default SaathiBankDetailsEdit;
