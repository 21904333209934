import Footer from "shared/Footer";

function NotFound() {
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg py-lg-0 fixed-top px-lg-5  `}
        style={{ backgroundColor: "#000000" }}
      >
        <a href="/" className="navbar-brand ms-4 ms-lg-0">
          <img
            className=""
            src="/assets/img/agrani-logo.png"
            alt="Capital for Farmers"
            style={{ height: "40px" }}
          />
        </a>
        <div className="d-lg-none d-md-none px-2">
          <div className="d-flex">
            <div className=" ms-2">
              <a
                href={"/dashboard"}
                className="btn btn-outline-primary py-2 px-3 fs-6 text-decoration-none"
                style={{
                  borderRadius: "5px",
                }}
              >
                Sign In
              </a>
            </div>
            <div className=" ms-2">
              <a
                className="btn text-white py-2 px-3 fs-6 text-decoration-none bg-orange"
                href="https://onboarding.agrani.io"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0 fs-6">
            <a href="/" className="nav-item nav-link  text-decoration-none">
              Home
            </a>
            <a
              href="/contact"
              className="nav-item nav-link active text-decoration-none"
            >
              Contact
            </a>
          </div>

          <div className="d-none d-lg-flex ms-2">
            <a
              className="btn btn-outline-primary py-2 px-3 fs-6 text-decoration-none"
              href="/dashboard"
              style={{
                borderRadius: "5px",
              }}
            >
              Sign In
            </a>
          </div>
          <div className="d-none d-lg-flex ms-2">
            <a
              className="btn text-white py-2 px-3 fs-6 text-decoration-none bg-orange"
              href="https://onboarding.agrani.io"
            >
              Create Account
            </a>
          </div>
        </div>
      </nav>
      <div class="custom-bg text-dark">
        <div class="d-flex align-items-center justify-content-center min-vh-100 px-2">
          <div class="text-center">
            <h1 class="display-1 fw-bold">404</h1>
            <p class="fs-2 fw-medium mt-4 fs-5">Oops! Page not found</p>
            <p class="mt-4 mb-5 fs-6">
              The page you're looking for doesn't exist or has been moved.
            </p>
            <a
              href="/"
              class="btn btn-primary fw-semibold rounded-pill px-4 py-2 custom-btn fs-6 text-decoration-none"
            >
              Go Home
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NotFound;
