import React, { useState, useEffect } from "react";
import { Col, Label, Row, Spinner } from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { API_URL } from "app/config";
import { selectCurrentToken, selectCurrentUser } from "features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "app/appSlice";

const SaathiCertificationEdit = ({
  saathiDetails,
  saathiDetailsRefetch,
  editable,
  setisEdit,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const user_token = useSelector(selectCurrentToken);
  const currentUser = useSelector(selectCurrentUser);
  var applicaintId = localStorage.getItem("applicant_id");
  const { handleSubmit, reset } = useForm();

  const [InsuranceExamStatus, setInsuranceExamStatus] = useState("");
  function handelInsuranceExamStatus(InsuranceExamStatus) {
    setInsuranceExamStatus(InsuranceExamStatus);
  }

  const [BankingExamStatus, setBankingExamStatus] = useState("");
  function handelBankingExamStatus(BankingExamStatus) {
    setBankingExamStatus(BankingExamStatus);
  }
  // ExamStatusData selectbox
  const ExamStatusData = [
    {
      label: "Exam Status",
      options: [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ],
    },
  ];
  // posp selectedFile
  const [pospselectedFile, setpospSelectedFile] = useState();
  const [pospIsSelected, setpospIsSelected] = useState(false);
  const POSPchangeHandler = (event) => {
    setpospSelectedFile(event.target.files[0]);
    setpospIsSelected(true);
  };

  // Banking selectedFile
  const [BankingselectedFile, setBankingSelectedFile] = useState();
  const [BankingIsSelected, setBankingIsSelected] = useState(false);
  const BankingchangeHandler = (event) => {
    setBankingSelectedFile(event.target.files[0]);
    setBankingIsSelected(true);
  };
  // posp selectedFile
  const [PoliceselectedFile, setPoliceSelectedFile] = useState("");
  const [PoliceIsSelected, setPoliceIsSelected] = useState(false);
  const PolicechangeHandler = (event) => {
    setPoliceSelectedFile(event.target.files[0]);
    setPoliceIsSelected(true);
  };

  let posp = InsuranceExamStatus === false ? "" : pospselectedFile;
  let Banking = BankingExamStatus === false ? "" : BankingselectedFile;
  const onSubmitSaathiData = (e) => {
    setLoader(true);
    const formData = new FormData();
    formData.append(
      "is_insurance_exam_passed",
      InsuranceExamStatus === ""
        ? saathiDetails?.data?.certification_details?.is_insurance_exam_passed
        : InsuranceExamStatus
    );

    formData.append(
      "is_banking_exam_passed",
      BankingExamStatus === ""
        ? saathiDetails?.data?.certification_details?.is_banking_exam_passed
        : BankingExamStatus
    );
    formData.append("posp_certificate", posp);
    formData.append("bank_certificate", Banking);
    formData.append("police_verification_certificate", PoliceselectedFile);
    formData.append("created_by", currentUser.sub);
    formData.append("created_by_name", currentUser.name);
    formData.append("applicant_id", applicaintId);

    fetch(`${API_URL}/empanelment/api/certification-details/`, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Token ${user_token}` },
    }).then((resp) => {
      const result = resp;
      if (result.status === 200 || result.status === 201) {
        resp
          .json()
          .then((response) => {
            swal("Success", {
              buttons: false,
              icon: "success",
              title: response.message,
              timer: 1500,
            });
            dispatch(setActiveTab(6));
            setLoader(false);
            setisEdit(false);
            saathiDetailsRefetch(localStorage.getItem("applicant_id"));
          })
          .catch((err) => console.log(err));
      } else {
        result
          .json()
          .then((response) => {
            swal("...Ooops", {
              buttons: false,
              icon: "warning",
              title: response.message,
              timer: 1500,
            });
            setLoader(false);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 47,
    }),
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmitSaathiData)}>
        <Col lg="12">
          <Row>
            <Row>
              <Col lg="3" className="mb-3">
                <Label
                  className="f_p text_c f_400"
                  name="is_insurance_exam_passed"
                >
                  Insurance Exam Passed
                </Label>
                <Select
                  value={InsuranceExamStatus.label}
                  placeholder={
                    saathiDetails?.data?.certification_details
                      ?.is_insurance_exam_passed === true
                      ? "Yes"
                      : "No" || "Select ..."
                  }
                  onChange={(InsuranceExamStatus) => {
                    handelInsuranceExamStatus(InsuranceExamStatus.value);
                  }}
                  options={ExamStatusData}
                  styles={customStyles}
                  // required={saathiDetails?.data?.certification_details?.is_insurance_exam_passed ? false : true}
                />
              </Col>
              <Col lg="3" className="mb-3">
                <Label
                  className="f_p text_c f_400"
                  name="is_banking_exam_passed"
                >
                  Bank Exam Passed
                </Label>
                <Select
                  value={BankingExamStatus?.Label}
                  onChange={(BankingExamStatus) => {
                    handelBankingExamStatus(BankingExamStatus.value);
                  }}
                  options={ExamStatusData}
                  styles={customStyles}
                  required={
                    saathiDetails?.data?.certification_details
                      ?.is_banking_exam_passed
                      ? false
                      : true
                  }
                  placeholder={
                    saathiDetails?.data?.certification_details
                      ?.is_banking_exam_passed === true
                      ? "Yes"
                      : "No" || "Select ..."
                  }
                />
              </Col>
              {InsuranceExamStatus == true ? (
                <Col lg="6" className="mb-3">
                  <Label className="f_p text_c f_400">
                    Upload POSP Certificate{" "}
                    <small>(Image Or Pdf Format Only*)</small>
                  </Label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*,.pdf"
                    name="posp_certificate"
                    onChange={POSPchangeHandler}
                  />
                </Col>
              ) : null}
              {BankingExamStatus == true ? (
                <Col lg="6" className="mb-3">
                  <Label className="f_p text_c f_400">
                    Upload Banking Certificate{" "}
                    <small>(Image Or Pdf Format Only*)</small>
                  </Label>
                  <input
                    type="file"
                    className="form-control"
                    name="bank_certificate"
                    accept="image/*,.pdf"
                    onChange={BankingchangeHandler}
                  />
                </Col>
              ) : null}
              <Col lg="6" className="mb-3">
                <Label className="f_p text_c f_400">
                  Upload Police Verification Certificate{" "}
                  <small>(Image Or Pdf Format Only*)</small>
                </Label>
                <input
                  type="file"
                  className="form-control"
                  id="policecertificate"
                  name="police_verification_certificate"
                  onChange={PolicechangeHandler}
                  accept="image/*,.pdf"
                />
              </Col>
            </Row>
          </Row>
        </Col>
        <Col
          lg={12}
          className={`text-center m-auto ${
            editable == true ? " mt-3" : "pb-lg-5  mt-lg-5 py-lg-0 py-3 py-md-3"
          }`}
        >
          <button type="submit" className="btn btn-success text-white p-3 ms-3">
            <span class="mdi mdi-content-save "></span>{" "}
            {saathiDetails?.data.certification_details === null ||
            saathiDetails?.data.certification_details === ""
              ? " Submit"
              : " Update"}{" "}
            Certification Details &nbsp;
            {loader ? <Spinner animation="border" size="sm"></Spinner> : ""}
          </button>
        </Col>
      </form>
    </React.Fragment>
  );
};

export default SaathiCertificationEdit;
