import Marquee from "react-fast-marquee";
import "../../components/agrani-website/css/style.css";
import AgraniHeader from "./AgraniHeader";
import HeroSection from "./HeroSection";
import AOSInit from "./AOSInit";
import ScrollAnimationWrapper from "./ScrollAnimationWrapper";
import getScrollAnimation from "utils/getScrollAnimation";
import { useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import CustomSubHeading from "./CustomSubHeading";
import { useNavigate } from "react-router-dom";
import kissan_qr from "../../assets/images/agrani-kissan.png";
import saathi_qr from "../../assets/images/agrani-saathi.png";
import agrani_pro_qr from "../../assets/images/agrani-pro.png";

const AgraniLandingPage = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/Dashboard")
  // }, [navigate])

  const brands = [
    {
      id: 1,
      name: "SBI",
      image:
        "https://static.vecteezy.com/system/resources/previews/020/975/663/non_2x/sbi-logo-sbi-icon-transparent-free-png.png",
      link: "https://www.onlinesbi.sbi/",
    },
    {
      id: 2,
      name: "Bank of Baroda",
      image:
        "https://1000logos.net/wp-content/uploads/2021/06/Bank-of-Baroda-logo.jpg",
      link:
        "https://play.google.com/store/apps/details?id=com.agrani.valuechain",
    },
    {
      id: 3,
      name: "BL Agro",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVUj5LUC2oRP7_Gt6oFZwJaeijjCxFD2Z4Bg&s",
      link: "https://blagro.org/",
    },
    {
      id: 4,
      name: "LeadsNexTech",
      image: "/assets/img/leadsnextech-logo.jpg",
      link: "https://www.leadsnextech.com",
    },
    {
      id: 5,
      name: "LeadsConnect",
      image: "/assets/img/leadsconnect.jpg",
      link: "https://www.leadsconnect.in",
    },
    {
      id: 6,
      name: "UPDASP",
      image:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhwH-1k8nMPM1cApR3yWnvhxE3NpCCd4DYgoV1AZnK10r9ycx_aUTkZhXdPfuYaVAfGKJOVXw80A27PhoLQf0wBqUysKxDBDLfkq7T7aIT63JuvfyfsmQiC1UebW57YjNxlgWn8AIBfrrE/s1600/updasp.jpg",
      link: "https://www.leadsconnect.in",
    },
    {
      id: 7,
      name: "Ensured Future",
      image: "/assets/img/leadsib.png",
      link: "https://ensuredfuture.in",
    },
  ];
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);

  const listUser = [
    {
      name: "Farmers' Productivity",
      number: "5,000+",
      description:
        "Empowering farmers with tools and insights to boost crop yields and efficiency.",
      icon: <i className="fas fa-seedling " style={{ fontSize: "25px" }}></i>,
    },
    {
      name: "Farmer Market Linkages",
      number: "1,500+",
      description:
        "Connecting farmers directly to marketplaces for fair trade and better profits.",
      icon: <i className="fas fa-store " style={{ fontSize: "25px" }}></i>,
    },
    {
      name: "Access to Credit",
      number: "3,000+",
      description:
        "Facilitating timely financial support through loans and credit access.",
      icon: <i className="fas fa-wallet " style={{ fontSize: "25px" }}></i>,
    },
    {
      name: "Sustainability Practices",
      number: "10,000+ acres",
      description:
        "Helping farmers adopt eco-friendly and sustainable farming techniques.",
      icon: <i className="fas fa-leaf" style={{ fontSize: "25px" }}></i>,
    },
  ];

  // return

  return (
    <>
      <AOSInit />
      <div className="">
        <AgraniHeader />
        <HeroSection />

        {/* khet se kitchen tak */}
        <div className="container-fluid feature fade-in mt-5 overflow-hidden">
          <div className="container">
            <div
              className="text-center mx-auto mb-5 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "500px" }}
            >
              <CustomSubHeading title={"Our Initiative"} />
              <h1 className="display-6 mb-5 mt-2">Khet Se Kitchen Tak</h1>
            </div>
            <div
              className="row g-4 "
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <div className="col-md-6 col-lg-6 col-xl-3  fadeInUp">
                <div className="feature-item p-4">
                  <div className="feature-icon p-4 ">
                    <i className="fas fa-landmark fa-3x text-primary"></i>
                  </div>
                  <h2>Sowing Stage</h2>
                  <p className="mb-4 fs-6">
                    Supporting farmers with capital through loans to kickstart
                    their farming activities.
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-6 col-xl-3 "
                data-wow-delay="0.6s"
              >
                <div className="feature-item p-4">
                  <div className="feature-icon p-4 ">
                    <i className="fas fa-seedling fa-3x text-primary"></i>
                  </div>
                  <h2>Growth Stage</h2>
                  <p className="mb-4 fs-6">
                    Offering insurance, fertilizers, pesticides, and other
                    essential farming inputs.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 col-xl-3 ">
                <div className="feature-item p-4">
                  <div className="feature-icon p-4 ">
                    {/* <span className="mdi mdi-chili-mild fs-1 text-primary"></span> */}
                    <i className="fas fa-pepper-hot fa-3x text-primary"></i>
                  </div>
                  <h2>Vegetative Stage</h2>
                  <p className="mb-4 fs-6">
                    Providing precision advisory and drone services for
                    real-time crop monitoring and spraying.
                  </p>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
                data-wow-delay="0.8s"
              >
                <div className="feature-item p-4">
                  <div className="feature-icon p-4 ">
                    <i className="fas fa-tractor fa-3x text-primary"></i>
                  </div>
                  <h2>Post Harvest Stage</h2>
                  <p className="mb-4 fs-6">
                    Enabling direct-to-consumer sales platforms for
                    manufacturers to connect with consumers.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center  mt-2">
              <img
                src="/assets/img/KHET SE KITCHEN TAK.gif"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </div>
          </div>
        </div>

        {/* Our Impact */}

        <div className="container-xxl py-5">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1200"
          >
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="d-inline-block rounded-pill bg-secondary2 text-primary py-1 px-3 mb-3 fs-6">
                  Our Impact
                </div>
                <h1
                  className="mb-4 "
                  style={{ lineHeight: "40px", fontSize: "60px" }}
                >
                  Connecting the Agricultural Value Chain
                </h1>
                <p className="mb-4 fs-6">
                  We collaborate across the agricultural value chain, from
                  farmers to manufacturers, to ensure efficient and sustainable
                  farming practices. Our partners are crucial to bringing
                  quality products and services directly to those who need them.
                </p>
                <p className="fs-6">
                  <i className="fa fa-check text-primary me-3"></i>Trusted
                  connections with industry partners
                </p>
                <p className="fs-6">
                  <i className="fa fa-check text-primary me-3"></i>Dedicated to
                  empowering farmers
                </p>
                <p className="fs-6">
                  <i className="fa fa-check text-primary me-3"></i>
                  Commitment to sustainable agriculture
                </p>
                <a
                  className="btn  rounded-pill py-3 px-5 mt-3 fs-6 text-decoration-none"
                  style={{ backgroundColor: "#FFF0E6" }}
                  href=""
                >
                  Explore More
                </a>
              </div>
              <div className="col-lg-6">
                <div className="rounded overflow-hidden">
                  <div className="row g-lg-0 g-md-0 g-3">
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                      <div className="text-center bg-primary py-5 px-4">
                        <img
                          className="img-fluid mb-4"
                          src="/assets/img/manufacturers.svg"
                          alt=""
                          style={{ width: "30%", height: "30%" }}
                        />
                        <h1
                          className="display-6 text-white"
                          data-toggle="counter-up"
                        >
                          1
                        </h1>
                        <span className="fs-5 fw-semi-bold text-white">
                          Manufacturers
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                      <div
                        className="text-center  py-5 px-4"
                        style={{ backgroundColor: "#FFF0E6" }}
                      >
                        <img
                          className="img-fluid mb-4"
                          src="/assets/img/traders.svg"
                          alt=""
                          style={{ width: "30%", height: "30%" }}
                        />
                        <h1 className="display-6" data-toggle="counter-up">
                          10+
                        </h1>
                        <span className="fs-5 fw-semi-bold text-primary">
                          Traders
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                      <div
                        className="text-center py-5 px-4"
                        style={{ backgroundColor: "#FFF0E6" }}
                      >
                        <img
                          className="img-fluid mb-4"
                          src="/assets/img/fpos.svg"
                          alt=""
                          style={{ width: "30%", height: "30%" }}
                        />
                        <h1 className="display-6" data-toggle="counter-up">
                          70+
                        </h1>
                        <span className="fs-5 fw-semi-bold text-primary">
                          FPOs
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                      <div className="text-center bg-primary py-5 px-4">
                        <img
                          className="img-fluid mb-4"
                          src="/assets/img/farmers.svg"
                          alt=""
                          style={{ width: "30%", height: "30%" }}
                        />
                        <h1
                          className="display-6 text-white"
                          data-toggle="counter-up"
                        >
                          5,000+
                        </h1>
                        <span className="fs-5  text-white">Farmers</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Leads Connect Start  */}
        <div className="container-xxl py-5 overflow-hidden">
          <div className="container">
            <div className="row g-5 mb-5">
              <div
                className="col-lg-6 d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
              >
                <div className="position-relative overflow-hidden ">
                  <img
                    className=" w-100 pt-5 pe-lg-5 rounded-3"
                    src="/assets/img/loan.jpg"
                    alt=""
                  />
                  <img
                    className="position-absolute top-0 end-0 bg-white ps-2"
                    src="/assets/img/leadsconnect.jpg"
                    alt=""
                    style={{
                      width: "200px",
                      height: "100px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="h-lg-100">
                  <div
                    className="d-inline-block rounded-pill bg-secondary2 text-primary py-1 px-3 mb-3 fs-6 font-weight-bold"
                    style={{ fontWeight: "600" }}
                  >
                    Leads Connect
                  </div>
                  <h1 className="display-6 mb-5">
                    Empowering Farmers with Tailored Financial Solutions
                  </h1>
                  <div className="bg-secondary2 border-bottom border-5 border-primary rounded p-4 mb-4 ">
                    <p className="text-dark mb-2 fs-6">
                      LeadsConnect is a cutting-edge agri-tech platform
                      specializing in data-driven financial services for the
                      agricultural sector. We offer personalized solutions to
                      meet the unique needs of farmers and stakeholders across
                      the agri-value chain.
                    </p>
                  </div>
                  <p className="mb-5  fs-6">
                    At LeadsConnect, we leverage technology to offer
                    comprehensive financial services, including loans,
                    insurance, and risk management, specifically tailored for
                    farmers. Our platform bridges the gap between financial
                    institutions and the agricultural community, making it
                    easier for farmers to access essential resources that drive
                    productivity and growth.
                  </p>

                  <div className="d-flex flex-lg-row flex-md-row flex-column gap-3">
                    <a
                      className="btn btn-primary py-2 px-3   text-decoration-none"
                      href="https://www.leadsconnect.in"
                      target="_blank"
                    >
                      <div className="d-flex justify-content-center align-items-center ">
                        <span style={{ fontSize: "14px" }}>
                          Visit Our Website
                        </span>
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2 ">
                          <span className="mdi mdi-arrow-right-thin fs-3"></span>
                        </div>
                      </div>
                    </a>
                    <a
                      className="btn btn-outline-primary py-2 px-3  text-decoration-none"
                      href="mailto:info@leadsconnect.in"
                    >
                      <div className="d-flex justify-content-center align-items-center ">
                        <span style={{ fontSize: "14px" }}>Contact Us</span>
                        <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                          <span className="mdi mdi-arrow-right-thin fs-3"></span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Agrani Kisan & Saathi Combined Start --> */}
        <div
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/assets/img/carousel-3.jpg)",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <ScrollAnimationWrapper>
            <motion.div variants={scrollAnimation}>
              <div className="container py-5">
                <div className="row g-5 align-items-center py-5 px-1">
                  <div
                    className="col-lg-6 wow fadeIn py-lg-5"
                    data-wow-delay="0.1s"
                  >
                    <div className="d-inline-block rounded-pill bg-primary text-white py-1 px-3 mb-3 fs-6 ">
                      Agrani Kisan & Agrani Saathi
                    </div>
                    <h1 className="display-6 text-white mb-5">
                      Empowering Farmers & Support Agents
                    </h1>
                    <p className="text-white fs-6 mb-4">
                      <strong>Agrani Kisan</strong> connects farmers with
                      financial services like loans and insurance, while
                      <strong> Agrani Saathi </strong>
                      enables Saathis to assist with farm mapping and advisory.
                    </p>
                    <p className="text-white fs-6 mb-0">
                      A unified ecosystem to ensure farmers receive the support
                      they need at every step.
                    </p>
                  </div>
                  <div
                    className="col-lg-6 "
                    data-wow-delay="0.5s"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="d-flex justify-content-around">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.agrani.user"
                        target="_blank"
                      >
                        <img
                          src="/assets/img/agrani-kisan-logo.png"
                          alt="Agrani Kisan Logo"
                          className="img-fluid img-hover"
                          style={{
                            objectFit: "cover",
                            width: "70%",
                            maxWidth: "280px",
                            borderRadius: "10px",
                            marginRight: "15px",
                          }}
                        />
                      </a>

                      <a
                        href="https://play.google.com/store/apps/details?id=com.agrani.saathi"
                        target="_blank"
                      >
                        <img
                          src="/assets/img/agrani-saathi-logo.png"
                          alt="Agrani Saathi Logo img-hover"
                          className="img-fluid"
                          style={{
                            objectFit: "cover",
                            width: "72%",
                            maxWidth: "280px",
                            borderRadius: "10px",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </ScrollAnimationWrapper>
        </div>

        {/* kissan future */}
        <div className="container my-lg-5 my-3 my-md-4" id="about">
          <div className="row align-items-center py-5 px-lg-4 px-2">
            <div className="col-lg-6  wow fadeInUp" data-wow-delay="0.1s">
              <CustomSubHeading title={"For Farmers"} />

              <h1 className="mb-3 " style={{ lineHeight: "40px" }}>
                Empowering Farmers for a Sustainable Future
              </h1>
              <p className="mb-4 fs-6 ">
                Farmers are at the heart of the agricultural value chain. We are
                committed to equipping them with the tools, knowledge, and
                opportunities they need to succeed. By leveraging innovation and
                collaboration, we aim to enhance productivity, ensure fair
                trade, and promote sustainable farming practices.
              </p>
              <div className="fs-6">
                <div className="d-flex align-items-center mb-2">
                  <i className="fa fa-chevron-right text-primary me-3"></i>
                  <span>
                    Access to quality seeds, fertilizers, and equipment
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="fa fa-chevron-right text-primary me-3"></i>
                  <span>
                    Direct connection with reliable buyers and markets
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="fa fa-chevron-right text-primary me-3"></i>
                  <span>
                    Training and resources for adopting sustainable practices
                  </span>
                </div>
              </div>
              <a
                className="btn  rounded-pill py-3 px-5 mt-3 fs-6 text-decoration-none"
                style={{ backgroundColor: "#FFF0E6" }}
                href=""
              >
                Explore More
              </a>
            </div>

            <div className="col-lg-6 col-md-10">
              <img
                src="/assets/img/farmers.png"
                alt="Agricultural Illustration"
                className="img-fluid bounce-effect"
              />
            </div>
          </div>

          {/* Stats Section */}
          <ScrollAnimationWrapper>
            <motion.div
              className="d-flex justify-content-center align-items-center"
              variants={scrollAnimation}
            >
              <div className="row d-flex bg-white py-2 rounded-3 text-center  mb-5 col-lg-10 shadow-box">
                {listUser.map((user, index) => (
                  <div
                    className="col-md-3 col-6 d-flex flex-column align-items-center py-3"
                    key={index}
                  >
                    <div
                      className="bg-warning bg-opacity-25 rounded-circle d-flex justify-content-center align-items-center mb-2"
                      style={{ width: 60, height: 60, color: "#ff6f0f" }}
                    >
                      {user.icon}
                    </div>
                    <h4 className="fw-bold">{user.number}</h4>
                    <p className="text-secondary fs-6">{user.name}</p>
                  </div>
                ))}
              </div>
            </motion.div>
          </ScrollAnimationWrapper>
        </div>

        {/* <!-- Agrani Pro Start --> */}
        <div
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/assets/img/carousel-5.jpg)",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="container-fluid donate my-5 py-5 "
        >
          <div className="container py-5">
            <div className="row g-5 d-flex align-items-center justify-content-center">
              <div
                className="col-lg-6 overflow-hidden "
                data-wow-delay="0.5s"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.agrani.valuechain"
                  target="_blank"
                  className=""
                >
                  <img
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    src="/assets/img/agrani-pro-logo.png"
                    alt="Agrani Pro Logo"
                    className="img-fluid img-hover"
                    style={{
                      objectFit: "cover",
                      height: "230px",
                      borderRadius: "10px",
                    }}
                  />
                </a>
              </div>
              <div className="col-lg-6" data-wow-delay="0.1s">
                <div className="d-inline-block rounded-pill bg-primary text-white py-1 px-3 mb-3 fs-6 ">
                  Agrani Pro
                </div>
                <h1 className="display-6 text-white mb-5">
                  Empowering Businesses with Data-Driven Insights
                </h1>
                <p className="text-white mb-4 fs-6">
                  <strong>Agrani Pro</strong> provides businesses with advanced
                  tools to optimize agricultural operations, monitor
                  productivity, and make data-driven decisions.
                </p>
                <p className="text-white mb-0 fs-6">
                  Gain access to crop analytics, market trends, and precision
                  farm management for efficient supply chain operations and
                  business growth.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Us */}
        <div className="container-xxl py-5">
          <div className="container">
            <div
              className="text-center mx-auto mb-5 "
              style={{ maxWidth: "500px" }}
            >
              <CustomSubHeading title={"Contact Us"} />
              <h1 className="">Contact us right now</h1>
            </div>
            <div className="row justify-content-center ">
              <div className="col-lg-8">
                <p className="text-center mb-5 fs-6">
                  Contact us anytime via email or phone, and we’ll respond
                  promptly to support your needs!
                </p>
                <div className="row g-5">
                  <div className="col-md-4 text-center">
                    <div className="btn-square mx-auto mb-3">
                      <span className="mdi mdi-email text-primary fs-1"></span>
                    </div>
                    <p className="mb-2 fs-6">info@leadsconnect.in</p>
                    <p className="mb-0 fs-6">info@agrani.io</p>
                  </div>
                  <div
                    className="col-md-3 text-center wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <div className="btn-square mx-auto mb-3">
                      <span className="mdi mdi-phone text-primary fs-1"></span>
                    </div>
                    <p className="mb-2 fs-6">
                      <a
                        href="tel:18001029232"
                        style={{ color: "#575757", textDecoration: "none" }}
                        className=" cursor"
                      >
                        18001029232
                      </a>
                    </p>
                    {/* <p className="mb-0 fs-6">18001029232</p> */}
                  </div>
                  <div
                    className="col-md-5 text-center wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="btn-square mx-auto mb-3">
                      <span className="mdi mdi-map-marker-radius text-primary fs-1"></span>
                    </div>
                    <p className="mb-2 fs-6">
                      1601, 16th Floor, World Trade Tower
                    </p>
                    <p className="mb-0 fs-6">Sector 16, Noida, Uttar Pradesh</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Companies */}
        <div className="container-xxl py-5">
          <div className="container">
            <div
              className="text-center mx-auto mb-5"
              style={{ maxWidth: "500px" }}
            >
              <CustomSubHeading title={" Our Partners"} />
              {/* <h1 className="display-6 mb-5 mt-2">
                Explore Our Group Companies & Brands
              </h1> */}
            </div>
            <Marquee
              gradient={true}
              gradientColor={[248, 251, 260]}
              gradientWidth={200}
              speed={50}
              pauseOnHover={true}
            >
              {brands.map((brand) => (
                <div
                  key={brand.id}
                  className="text-center border rounded mx-3 d-flex justify-content-center align-items-center img-hover"
                  style={{
                    width: "fit-content",
                    height: "130px",
                    overflow: "hidden",
                    padding: "10px",
                  }}
                >
                  <a
                    href={brand.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={brand.image}
                      alt={`${brand.name} Logo`}
                      className="img-fluid "
                      style={{
                        width: "200px",
                        height: "140px",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
              ))}
            </Marquee>
          </div>
        </div>

        {/* <!-- Footer --> */}
        <div
          className="container-fluid  text-white-50 footer mt-5 pt-5 wow fadeIn p-3"
          style={{ backgroundColor: "#000000" }}
          data-wow-delay="0.1s"
        >
          <div className="container py-lg-5 py-3">
            <div className="row g-5">
              <div className="col-lg-4 col-md-6 ">
                <img
                  className="mb-4"
                  src="/assets/img/agrani-logo.png"
                  alt="Capital for Farmers"
                  style={{ height: "40px" }}
                />
                <p className="fs-6 offwhite">
                  To Connect Farm Value Chain for enabling sustainable, scalable
                  and profitable agri-business ecosystem.
                </p>
                <div className="d-flex pt-2 ">
                  <a className="btn btn-square me-2" href="">
                    <span className="mdi mdi-twitter fs-4"></span>
                  </a>
                  <a className="btn btn-square me-2" href="">
                    <span className="mdi mdi-facebook fs-4"></span>
                  </a>
                  <a className="btn btn-square me-2" href="">
                    <span className="mdi mdi-youtube fs-4"></span>
                  </a>
                  <a className="btn btn-square me-2" href="">
                    <span className="mdi mdi-linkedin fs-4"></span>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 offwhite">
                <h5 className="text-light mb-4">Address</h5>
                <p>
                  <span className="mdi mdi-map-marker-radius  me-2 fs-5"></span>
                  Sector 16, Noida, Uttar Pradesh
                </p>
                <p>
                  <span className="mdi mdi-phone  me-2 fs-5"></span>
                  18001029232
                </p>
                <p>
                  <span className="mdi mdi-email  me-2 fs-5"></span>
                  info@leadsconnect.in
                </p>
              </div>
              <div className="col-md-4 col-lg-4">
                <h5 className="text-light mb-4">
                  Scan the QR Code to Download our App
                </h5>

                <div className="d-flex  align-items-center gap-lg-5 gap-3 gap-md-4">
                  <div>
                    <img
                      src={kissan_qr}
                      alt="Qr"
                      className="img-fluid"
                      style={{ height: "100px" }}
                    />
                    {/* <img
                      src="/assets/img/agrani-pro-logo.png"
                      style={{ height: "60px" }}
                      className="mt-1"
                    /> */}
                    <p className="offwhite mt-1">Agrani Kissan</p>
                  </div>
                  <div>
                    <img
                      src={saathi_qr}
                      alt="Qr"
                      className="img-fluid"
                      style={{ height: "100px" }}
                    />
                    <p className="offwhite mt-1">Agrani Saathi</p>
                  </div>

                  <div>
                    <img
                      src={agrani_pro_qr}
                      alt="Qr"
                      className="img-fluid"
                      style={{ height: "100px" }}
                    />
                    <p className="offwhite mt-1">Agrani Pro</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 ">
                <h5 className="text-light mb-4">Quick Links</h5>
                <a
                  className="btn btn-link text-decoration-none mb-2"
                  style={{ fontSize: "14px" }}
                  href=""
                >
                  About Us
                </a>
                <a
                  className="btn btn-link text-decoration-none mb-2 "
                  style={{ fontSize: "14px" }}
                  href=""
                >
                  Contact Us
                </a>
                <a
                  className="btn btn-link text-decoration-none mb-2"
                  style={{ fontSize: "14px" }}
                  href=""
                >
                  Our Services
                </a>
                <a
                  className="btn btn-link text-decoration-none mb-2"
                  style={{ fontSize: "14px" }}
                  href=""
                >
                  Terms & Condition
                </a>
                <a
                  className="btn btn-link text-decoration-none mb-2"
                  style={{ fontSize: "14px" }}
                  href=""
                >
                  Support
                </a>
              </div> */}
            </div>
          </div>
          <div className="container-fluid copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                  &copy;
                  <a
                    href="https://www.leadsconnect.in"
                    target="_blank"
                    className="mx-1"
                  >
                    LeadsConnect Services Pvt. Ltd
                  </a>
                  , All Right Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgraniLandingPage;
