import React from "react";
import Slider from "react-slick";

export default function HeroSection() {
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          right: "20px",
          transform: "translateY(-50%)",
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fa fa-chevron-right"
          style={{ fontSize: "24px", color: "#fff" }}
        ></i>
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          left: "20px",
          transform: "translateY(-50%)",
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fa fa-chevron-left"
          style={{ fontSize: "24px", color: "#fff" }}
        ></i>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          width: "100%",
          textAlign: "center",
          zIndex: 10,
        }}
      >
        <ul
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 0,
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="dot"
        style={{
          width: "9px",
          height: "9px",
          background: "lightgray",
          borderRadius: "50%",
          margin: "0 8px",
        }}
      ></div>
    ),
  };

  const slides = [
    {
      id: 1,
      image: "/assets/img/carousel-1.png",
      title:
        "Empowering Farmers & Agri Stakeholders with SpaceTech-AI driven Analytics",
      description:
        "Providing timely Advisory to farmers & FPOs for enhancing the yield and productivity and offering protection against extreme weather events and disasters.",
      buttonText: "Get Started",
      buttonLink: "https://onboarding.agrani.io",
    },
    {
      id: 2,
      image: "/assets/img/carousel-3.jpg",
      title: "Crop Advisory and Analytics",
      description:
        "Offering real-time crop monitoring and insights through satellite & weather data to optimize farming practices.",
      buttonText: "Get Started",
      buttonLink: "https://onboarding.agrani.io",
    },
    {
      id: 3,
      image: "/assets/img/carousel-2.jpg",
      title: "Essential Fintech Interventions",
      description:
        "Enabling timely access to credit and insurance for efficient Farming and Risk management.",
      buttonText: "Get Started",
      buttonLink: "https://onboarding.agrani.io",
    },

    // {
    //   id: 4,
    //   image: "/assets/img/carousel-4.jpg",
    //   title: "Precision Farming Services",
    //   description:
    //     "Advanced drone services for accurate farm monitoring and efficient pesticide spraying.",
    //   buttonText: "Get Started",
    //   buttonLink: "https://onboarding.agrani.io",
    // },
    {
      id: 5,
      image: "/assets/img/carousel-5.jpg",
      title: "Market Linkages",
      description:
        "Connecting farmers and FPOs with traders, manufacturers, and retailers to trade their produce effectively.",
      buttonText: "Get Started",
      buttonLink: "https://onboarding.agrani.io",
    },
    {
      id: 6,
      image: "/assets/img/carousel-6.jpg",
      title: "Direct Farm-to-Consumer Marketplace",
      description:
        "Enabling consumers to buy fresh, farm-sourced foods, from oils and grains to vegetables, directly from farmers and FPOs.",
      buttonText: "Get Started",
      buttonLink: "https://onboarding.agrani.io",
    },
  ];

  return (
    <div className="hero-section fade-in">
      <Slider {...settings}>
        {slides.map((slide) => (
          <div key={slide.id} className="carousel-slide">
            <div
              className="carousel-image hero_carousel"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

                position: "relative",
              }}
            >
              <div
                className="carousel-caption d-flex flex-column col-lg-7 col-12"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  textAlign: "center",
                  padding: "20px",
                  background: "transparent",
                  borderRadius: "10px",
                  height: "auto",
                }}
              >
                <h1 className="display-2 fs-1 mb-3 ">{slide.title}</h1>
                <p className="fs-5 mb-4" style={{ color: "#ffffffd6" }}>
                  {slide.description}
                </p>
                <a
                  className="btn btn-primary bg-orange get-started py-2 px-4 fs-6 text-decoration-none"
                  style={{ borderRadius: "8px" }}
                  href={slide.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {slide.buttonText}
                  <span className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                    <i className="fa fa-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
